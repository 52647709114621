@charset "utf-8";

/* ==========================================================================
   Common
   ========================================================================== */

body{
  background:#eee;
  color:#454545;
  font-family:'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
  font-size:13px;
  line-height:1.8;
  margin:0;
  padding:0;
}
#wrapper{
  width:100%;
  padding:20px 0 0 0;
  @media #{$mq_max_940} {
    padding-top: 58px;
    .container{
      width: 96%;
      margin: 0 2%;
      &.white {
        width: 100%;
        margin: 0;
      }
    }
  }
}
.container, .inner{
  width:940px;
  margin:0 auto;
}
@media #{$mq_max_940} {
  .container {
    width: 100%;
  }
  .inner {
    width: 100%;
  }
}
.search-container.white{
  background:#fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding:25px;
  width:940px;
  @media #{$mq_max_940} {
    width: 100%;
    margin: 0 15px;
    border-radius: 0px;
  }
}
.container {
  &.white {
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 25px;
    width: 890px;
    margin: 0 auto 30px auto;
    @media #{$mq_max_940} {
      width: 100%;
      margin: 0 0 30px 0;
      padding: 0;
      border-radius: 0px;
      p {
        padding: 1.2rem 2%;
      }
    }
  }
  &.gray {
    background: #d6d6d6;
    padding: 25px 0 35px 0;
    width: 100%;
  }
}
a {
  color: #06b;
  text-decoration: none;
  -webkit-transition: all ease-in-out .5s;
  -moz-transition: all ease-in-out .5s;
  -o-transition: all ease-in-out .5s;
  transition: all ease-in-out .5s;

  &:visited {
    color: #06b;
    text-decoration: none;
  }

  &:hover {
    color: #06b;
    text-decoration: underline;
  }

  &:active {
    color: #06b;
    text-decoration: none;
  }

  img {
    border: none;
  }
}
.row {
  display: flex;
  align-items: center;
  border-collapse: collapse;
  & [class^="column-"], & [class*=" column-"] {
    display: flex;
  }
  & .column-2 {
    /*width: 50%;*/
  }
  & .column-3 {
    width: 290px;
    @media #{$mq_max_940} {
      width: 100%;
      margin: 0 2%;
    }
  }
  & .column-4 {
    width: 214px;
  }
}

/* ==========================================================================
   Class
   ========================================================================== */
.tright{
  text-align:right;
}
.tcenter{
  text-align:center;
}
.end{
  padding-bottom:45px;
}
.force-margin-zero{
  margin-bottom:0 !important;
}
.force-margin-s{
  margin-bottom:15px !important;
}
.force-margin-m{
  margin-bottom:25px !important;
}
.force-margin-l{
  margin-bottom:35px !important;
}
.force-margin-xl{
  margin-bottom:45px !important;
}
.force-margin-xxl{
  margin-bottom:55px !important;
}
