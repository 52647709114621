@charset "utf-8";

/* ==========================================================================
   Second
   ========================================================================== */

#search-result {
  margin: 0 auto 30px auto;
}

.heading {
  margin: 0 auto 20px auto;
  @media #{$mq_max_940} {
    width: 96%;
    margin: 1.2rem 2%;
  }
  h1 {
    border-left: 7px solid #eeff11;
    font-size: 22px;
    font-weight: normal;
    line-height: 1.2;
    padding: 0 0 0 12px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    .cart-button, .favorite-button {
      position: absolute;
      display: inline-block;
      top: 0;
      right: 0;
      @include fontsize(14);
      @media #{$mq_max_940} {
        .button {
          @include fontsize(11);
        }
      }
    }
    .cart-button {
      right: 220px;
      @media #{$mq_max_940} {
        top: 66px;
        right: 0px;
      }
    }
  }

}

.container {
  .column-left .white, .column-right .white {
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 20px;
  }

  .column-left {
    width: 182px;
    @media #{$mq_max_940} {
      width: 100%;
    }
    p {
      background: #fff;
      margin: 0;

      img {
        padding: 5px;
      }
    }
  }

  .column-right {
    width: 720px;
    margin: 0 0 35px 0;
    @media #{$mq_max_940} {
      width: 100%;
    }
    table td {
      .row {
        display: -webkit-flex;
        display: flex;
        width: 350px;
      }

      div {
        border-right: 3px solid #eee;
        -webkit-box-flex: 1;
        box-flex: 1;
        text-align: center;

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.product-detail-container {
  align-items: flex-start;
  justify-content: space-between;
  @media #{$mq_max_940} {
    display: block;
    .column-left {
      text-align: center;
    }
  }
}

.compare-table td .light-color, .container .column-right table td .light-color {
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 450px;
  -moz-border-radius: 450px;
  border-radius: 450px;
  content: " ";
  display: inline-block;
  height: 8px;
  padding: 0;
  width: 8px;
}

.compare-table td .light-color.blue, .container .column-right table td .light-color.blue {
  background: #006AB7;
}

.compare-table td .light-color.green, .container .column-right table td .light-color.green {
  background: #8DC556;
}

.compare-table td .light-color.amber, .container .column-right table td .light-color.amber {
  background: #F6AD3B;
}

.compare-table td .light-color.red, .container .column-right table td .light-color.red {
  background: #E61B1B;
}

.compare-table td .light-color.white, .container .column-right table td .light-color.white {
  background: #ffffff;
}

.compare-table td .light-color.warm-white, .container .column-right table td .light-color.warm-white {
  background: #FEF555;
}

.compare-table td .light-color.bluish-green, .container .column-right table td .light-color.bluish-green {
  background: #82CCD2;
}

.compare-table td .light-color.uv, .container .column-right table td .light-color.uv {
  background: #924898;
}

.compare-table td .light-color.candle-color, .container .column-right table td .light-color.candle-color {
  background: #FEE8A9;
}

.compare-table td .light-color.yellow, .container .column-right table td .light-color.yellow {
  background: #FDEF55;
}

.compare-table td .light-color.royal-blue, .container .column-right table td .light-color.royal-blue {
  background: #154AB7;
}

.index-navi {
  width: 705px;

  .column-3 {
    border: 1px solid #ccc;
    display: inline-table;
    width: 208px;
    margin: 0 25px 25px 0;

    a {
      display: block;
      padding: 9px;

      span {
        margin: 0 0 0 10px;
      }
    }
  }
}

.product-indexes {
  width: 705px;

  .index {
    display: inline-table;
    width: 210px;
    margin: 0 25px 25px 0;

    h3 {
      font-size: 15px;
      font-weight: normal;
      padding: 0 0 10px 0;
      margin: 0;
    }

    .row {
      width: 210px;
    }

    .left {
      display: table-cell;
      width: 70px;
      padding: 0 15px 0 0;
      vertical-align: top;
    }

    .right {
      display: table-cell;
      width: 115px;
      vertical-align: top;

      p {
        font-size: 12px;
        padding: 0;
        margin: 0;
      }
    }
  }
}

/* ==========================================================================
   Product detail
   ========================================================================== */

.product-detail {
  margin: 0 0 35px 0;

  .column-2 {
    &:first-child {
      width: 260px;
      padding: 0 40px 0 0;
    }

    &:last-child {
      width: 640px;
    }

    dt {
      position: absolute;
      margin: 10px 0;
    }

    dd {
      border-bottom: 1px dashed #ccc;
      padding: 10px 0 10px 130px;
      margin: 0;
    }
  }
}

.down-load {
  background: #efefef;
  padding: 15px 20px;
  margin: 0 0 35px 0;
}

.container h1 {
  position: relative;
}

.heading h1 .new {
  background: #EEFF11;
  color: #66AADD;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 3px 5px;
  margin: 0 0 0 10px;
  vertical-align: top;
}

.container {
  h1 .favorit-button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
  }

  h2 {
    font-size: 18px;
    font-weight: normal;
    position: relative;
  }
}

.inner h2 {
  font-size: 18px;
  font-weight: normal;
  position: relative;
  @media #{$mq_max_940} {
    margin: 0 2%;
    width: 96%;
  }
}

.container {
  h2 .button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
  }

  h3 {
    font-size: 16px;
    font-weight: normal;
    position: relative;
  }
}

.inner h3 {
  font-size: 16px;
  font-weight: normal;
  position: relative;
}

.container p.tcenter img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
}

.product-detail-table {
  border-collapse: collapse;

  th {
    background: #BBDDFF;
    border: 3px solid #eee;
    font-weight: normal;
    padding: 12px;
    text-align: left;
  }

  td {
    background: #fff;
    border: 3px solid #eee;

    table {
      border: none;

      td {
        border: none;
        border-right: 1px solid #ddd;
        text-align: center;
        padding: 0;

        &:last-child {
          border: none;
        }
      }

      &[class^="series-col"] td, &[class*="series-col"] td {
        padding: 10px 0;
      }

      &.series-col1 td {
        width: 360px;
      }

      &.series-col2 td {
        width: 180px;
      }

      &.series-col3 td {
        width: 120px;
      }

      &.series-col4 td {
        width: 90px;
      }

      &.series-col5 td {
        width: 72px;
      }

      &.series-col6 td {
        width: 60px;
      }
    }
  }
}

/* ==========================================================================
   UVLED
   ========================================================================== */

.inner.uvled{
  margin:0 auto 35px auto;
}

/* ==========================================================================
   Swipeable
   ========================================================================== */

.dataTables_scroll{
  overflow-y: scroll;
  @media #{$mq_max_940} {
    .dataTables_scrollHead {
      overflow: unset !important;
      min-width: 940px;
    }
    .dataTables_scrollBody {
      overflow: unset !important;
      height: 160px !important;
      min-width: 940px;
    }
  }
}

@keyframes swipeable {
  0% {
    opacity: 0;
    left: 60%;
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    left: 40%;
  }
}

.swipeable {
  width: 100%;
  position: relative;
  @media #{$mq_max_940} {
    &::after {
      content: "";
      width: 40px;
      height: 40px;
      background-image: url(../images/swipeable.png);
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      animation: swipeable 1s ease-in 1s infinite alternate forwards running;
    }
    &.swiped::after {
      background-image: none;
    }
  }
}
@media #{$mq_max_940} {
  .uvled {
    .swipeable {
      overflow-y: scroll;
      height: 200px;
      .product-list-table {
        min-width: 940px;
      }
    }
  }
}