@charset "utf-8";

/* ==========================================================================
   Font
   ========================================================================== */

@font-face {
  font-family: 'shinkoh';
  src:  url('../fonts/shinkoh.eot?gsqb96');
  src:  url('../fonts/shinkoh.eot?gsqb96#iefix') format('embedded-opentype'),
  url('../fonts/shinkoh.ttf?gsqb96') format('truetype'),
  url('../fonts/shinkoh.woff?gsqb96') format('woff'),
  url('../fonts/shinkoh.svg?gsqb96#shinkoh') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shinkoh' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cart:before {
  content: "\e93a";
}
.icon-download:before {
  content: "\e600";
}
.icon-inquiry:before {
  content: "\e601";
}
.icon-search:before {
  content: "\e602";
}
.icon-arrow-down2:before {
  content: "\e603";
}
.icon-home3:before {
  content: "\e902";
}
.icon-office:before {
  content: "\e903";
}
.icon-image:before {
  content: "\e90d";
}
.icon-images:before {
  content: "\e90e";
}
.icon-file-text:before {
  content: "\e922";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-files-empty:before {
  content: "\e925";
}
.icon-file-text2:before {
  content: "\e926";
}
.icon-file-picture:before {
  content: "\e927";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-phone:before {
  content: "\e942";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-user:before {
  content: "\e971";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-list:before {
  content: "\e9ba";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-tree:before {
  content: "\e9bc";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-question:before {
  content: "\ea09";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-arrow-up:before {
  content: "\ea32";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-new-tab:before {
  content: "\ea7e";
}
.icon-file-pdf:before {
  content: "\eada";
}
.icon-file-word:before {
  content: "\eadc";
}
.icon-file-excel:before {
  content: "\eadd";
}