@charset "utf-8";

/* ==========================================================================
   CMS settings
   ========================================================================== */

.ratio2x8 .column-2 {
  &:first-child {
    width: 172px;
    padding-right: 30px;
  }

  &:last-child {
    width: 688px;
  }
}

.ratio3x7 .column-2 {
  &:first-child {
    width: 258px;
    padding-right: 30px;
  }

  &:last-child {
    width: 602px;
  }
}

.ratio4x6 .column-2 {
  &:first-child {
    width: 344px;
    padding-right: 30px;
  }

  &:last-child {
    width: 516px;
  }
}

.ratio5x5 .column-2 {
  &:first-child {
    width: 430px;
    padding-right: 30px;
  }

  &:last-child {
    width: 430px;
  }
}

.ratio6x4 .column-2 {
  &:first-child {
    width: 516px;
    padding-right: 30px;
  }

  &:last-child {
    width: 344px;
  }
}

.ratio7x3 .column-2 {
  &:first-child {
    width: 602px;
    padding-right: 35px;
  }

  &:last-child {
    width: 258px;
  }
}

.ratio8x2 .column-2 {
  &:first-child {
    width: 688px;
    padding-right: 35px;
  }

  &:last-child {
    width: 172px;
  }
}

.row[class*="ratio"] {
  & [class^="column-"], & [class*=" column-"] {
    display: block;
  }
}

@media #{$mq_max_940} {
  .row[class*="ratio"] {
    display: block;
    .column-2 {
      width: 100%;
      &:first-child {
        padding-right: 0;
      }
    }
  }
}

[class^="ratio"] .column-2 img, [class*=" ratio"] .column-2 img {
  width: 100%;
}

[class^="ratio"] .column-2 img.circle, [class*=" ratio"] .column-2 img.circle {
  -webkit-border-radius: 450px;
  -moz-border-radius: 450px;
  border-radius: 450px;
}

table {
  &.ratio3x7 {
    .col-0 {
      width: 30%;
    }

    .col-1 {
      width: 70%;
    }
  }

  &.ratio4x6 {
    .col-0 {
      width: 40%;
    }

    .col-1 {
      width: 60%;
    }
  }

  &.ratio5x5 {
    .col-0, .col-1 {
      width: 50%;
    }
  }

  &.ratio6x4 {
    .col-0 {
      width: 60%;
    }

    .col-1 {
      width: 40%;
    }
  }

  &.ratio7x3 {
    .col-0 {
      width: 70%;
    }

    .col-1 {
      width: 30%;
    }
  }
}
