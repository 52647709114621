@charset "utf-8";

/* ==========================================================================
   To page top
   ========================================================================== */

.page-top {
  position:fixed;
  bottom:20px;
  right:30px;
  z-index: 300;
}

/* ==========================================================================
   Bread-crumb
   ========================================================================== */

.bread-crumb{
  font-size:11px;
  margin:0 0 15px 0;
  @media #{$mq_max_940} {
    display: none;
  }
}

/* ==========================================================================
   Table
   ========================================================================== */

.yearly ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0;
  }
}

/* ==========================================================================
   Table
   ========================================================================== */

.normal-table {
  border-collapse: collapse;

  th {
    background: #BBDDFF;
    border: 3px solid #eee;
    font-weight: normal;
    padding: 12px;
    text-align: left;
  }

  td {
    background: #fff;
    padding: 12px;
    border: 3px solid #eee;
  }
}

.product-list-table {
  border-collapse: collapse;

  th {
    font-size: 11px;
    font-weight: normal;
  }

  .nowrap {
    white-space: nowrap;
  }

  td {
    font-size: 12px;
    padding: 8px;
    border-left: 1px solid #e1e1e1;

    &:first-child {
      border-left: none;
    }

    &.column1, &.column8 {
      white-space: nowrap;
    }

    &.column4, &.column5, &.column6, &.column7, &.column8, &.column9, &.column10 {
      padding: 0;
    }
  }

  tr:first-child td {
    border-top: none;
  }

  td {
    span.fred {
      color: #f00;
      padding: 0 0 0 5px;
    }

    a {
      text-decoration: none;
    }

    div {
      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        border-bottom: none;
      }
    }

    [class^="icon-"], [class*=" icon-"] {
      font-size: 14px;
    }

    &.column3, &.column4, &.column5, &.column6, &.column7, &.column8, &.column9, &.column10 {
      text-align: center;
    }
  }
}

.product-detail-link {
  display: block;
}

.product-list-table {
  td {
    .new {
      background: #EEFF11;
      color: #66AADD;
      display: inline-block;
      font-size: 11px;
      line-height: 1;
      padding: 2px 3px;
      margin: 0 5px 0 0;
      vertical-align: top;
    }

    [class^="icon-"], [class*=" icon-"] {
      color: #828282;
    }

    a.remove-favorite span {
      color: #06d !important;
    }

    .light-color {
      border: 1px solid #e1e1e1;
      -webkit-border-radius: 450px;
      -moz-border-radius: 450px;
      border-radius: 450px;
      content: " ";
      display: inline-block;
      height: 8px;
      width: 8px;

      &.blue {
        background: #006AB7;
      }

      &.green {
        background: #8DC556;
      }

      &.amber {
        background: #F6AD3B;
      }

      &.red {
        background: #E61B1B;
      }

      &.white {
        background: #ffffff;
      }

      &.warm-white {
        background: #FEF555;
      }

      &.bluish-green {
        background: #82CCD2;
      }

      &.uv {
        background: #924898;
      }

      &.candle-color {
        background: #FEE8A9;
      }

      &.yellow {
        background: #FDEF55;
      }

      &.royal-blue {
        background: #154AB7;
      }
    }

    .product-list-table td a {
      text-decoration: none;
    }
  }

  &.uv {
    thead {
      th {
        background: #d6d6d6;
        font-weight: normal;
        padding: 10px;
      }

      td {
        padding: 10px;
      }
    }

    tbody {
      th {
        background-color: #fff;
        border-bottom: 1px solid #d6d6d6;
      }

      td {
        background-color: #fff;
        border-bottom: 1px solid #d6d6d6;

        img {
          text-align: center;
        }
      }
    }
  }
}

.product-list-function {
  background: #BBDDFF;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
  display: block;
  position: relative;
  padding: 7px 7px 7px 173px;
  margin: 0;
  z-index: 100;
  @media #{$mq_max_940} {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: none;
    padding: 0;
  }
  .check-all {
    background: #6ad !important;
    -webkit-border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    border-top-left-radius: 10px;
    border: none;
    color: #fff !important;
    font-size: 13px !important;
    left: 0;
    padding: 12px 12px !important;
    position: absolute;
    top: 0;
    width: 140px;
    @media #{$mq_max_940} {
      -webkit-border-top-left-radius: 0px;
      -moz-border-radius-topleft: 0px;
      border-top-left-radius: 0px;
      position: relative;
      width: 100%;
      padding: 12px 0 !important;
      order: 2;
    }
    &:hover {
      text-decoration: none;
    }
  }

  .button.small {
    background: #0066BB;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: none;
    display: inline-block;
    color: #fff !important;
    font-size: 12px !important;
    padding: 6px 10px;
    margin: 0 0 0 0;
    box-shadow: rgb(0, 51, 102) 0px 2px 0px 0px;
    -webkit-box-shadow: rgb(0, 51, 102) 0px 2px 0px 0px;
    -moz-box-shadow: rgb(0, 51, 102) 0px 2px 0px 0px;

    &:hover {
      text-decoration: none;
    }
  }

  &.up {
    -webkit-border-top-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-left-radius: 10px;
    @media #{$mq_max_940} {
      flex-direction: column-reverse;
      -webkit-border-bottom-left-radius: 0px;
      -moz-border-radius-bottomleft: 0px;
      border-bottom-left-radius: 0px;
    }
    .check-all {
      -webkit-border-top-left-radius: 0px;
      -moz-border-radius-topleft: 0px;
      border-top-left-radius: 0px;
      -webkit-border-bottom-left-radius: 10px;
      -moz-border-radius-bottomleft: 10px;
      border-bottom-left-radius: 10px;
      @media #{$mq_max_940} {
        -webkit-border-bottom-left-radius: 0px;
        -moz-border-radius-bottomleft: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }

  &.down:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-top: 15px solid #6ad;
  }

  &.up:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10px;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-bottom: 15px solid #6ad;
  }

  ul {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
    @media #{$mq_max_940} {
      order: 1;
      width: 96%;
      padding: 0.5rem 2%;
    }
    li {
      display: inline;
      padding: 0 10px 0 0;
      &:last-child {
        @media #{$mq_max_940} {
          padding: 0;
        }
        text-align: right;
        float: right;
        margin: 2px;

        select {
          width: 60px;
          min-width: 60px;
        }
      }
    }
  }

  form {
    display: none;
  }
}

.dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_paginate {
  display: none;
}

/* ==========================================================================
   Favorite
   ========================================================================== */

.favorite-block {
  display: none;
  -moz-box-shadow: rgba(0, 0, 0, 0.25) 0 3px 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 3px 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 3px 10px;
  background: #d6d6d6;
  width: 704px;
  right: -800px;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  @media #{$mq_max_940} {
    width: 222px;
    right: -250px;
  }

  .top {
    margin: 19px;
    min-height: 120px;
    @media #{$mq_max_940} {
      max-height: 120px;
      overflow-x: auto;
    }
  }

  .bottom {
    background: #777;
    padding: 10px 0;
    width: 704px;
    display: flex;
    justify-content: center;
    @media #{$mq_max_940} {
      width: 222px;
    }

    .row {
      width: 604px;
      margin: 0 0 0 65px;
      @media #{$mq_max_940} {
        width: 100%;
        margin: 0;
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-top: 15px solid #777;
  }

  .row {
    display: flex;
    @media #{$mq_max_940} {
      flex-direction: column;
    }
  }

  .column-5 {
    width: 118px;
    padding: 0 19px 0 0;
    @media #{$mq_max_940} {
      padding: 0 0 19px 0;
      &:last-child {
        padding: 0;
      }
    }

    a {
      background: #fff;
      border-bottom: 5px solid #454545;
      display: block;
      height: 106px;
      overflow: hidden;
      padding: 5px;
      position: relative;
      text-decoration: none;
      width: 108px;

      img {
        background: #e1e1e1;
        display: inline-block;
        margin: 0 0 6px 0;
      }

      .icon-cancel-circle {
        background: #fff;
        -webkit-border-bottom-left-radius: 6px;
        -moz-border-radius-bottomleft: 6px;
        border-bottom-left-radius: 6px;
        color: #454545;
        right: 0px;
        padding: 4px;
        position: absolute;
        top: 0px;
      }
    }
  }

  .column-3 {
    margin: 0 10px 0 0;
    &:last-child {
      margin: 0;
    }
    @media #{$mq_max_940} {
      margin: 0;
    }
  }

  .button {
    padding: 10px 20px;
    @media #{$mq_max_940} {
      @include fontsize(12);
      padding: 10px 10px;
    }
  }

  .compare {
    width: 120px;
    @media #{$mq_max_940}{
      width: 186px;
      margin-bottom: 10px;
      a {
        width: 100%;
      }
    }
  }

  .datasheet {
    width: 230px;
    @media #{$mq_max_940}{
      width: 186px;
      margin-bottom: 10px;
      a {
        width: 100%;
      }
    }
  }

  .inquiry {
    width: 150px;
    @media #{$mq_max_940}{
      width: 186px;
      a {
        width: 100%;
      }
    }
  }
}

.click-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
}

.favorite-block .loading {
  display: none;
  padding: 50px 0 0 0;
  text-align: center;
  width: 666px;
  @media #{$mq_max_940} {
    width: 100%;
  }
}

.no-favorite {
  padding: 50px 0 0 0;
  text-align: center;
  width: 666px;
  @media #{$mq_max_940} {
    width: 100%;
  }
}

/* ==========================================================================
   History
   ========================================================================== */

#histories {

  .row {
    min-height: 132px;
    @media #{$mq_max_940}{
      width: 100%;
      overflow-y: auto;
      scroll-behavior: smooth;
    }
  }

  .column-7 {
    width: 118px;
    padding: 0 19px 0 0;

    a {
      background: #fff;
      border-bottom: 5px solid #454545;
      display: block;
      height: 106px;
      overflow: hidden;
      padding: 5px;
      text-decoration: none;
      position: relative;
      width: 108px;

      img {
        background: #e1e1e1;
        display: inline-block;
        margin: 0 0 6px 0;
      }

      .icon-cancel-circle {
        background: #fff;
        -webkit-border-bottom-left-radius: 6px;
        -moz-border-radius-bottomleft: 6px;
        border-bottom-left-radius: 6px;
        color: #454545;
        right: 0px;
        padding: 4px;
        position: absolute;
        top: 0px;
      }
    }
  }

  .loading {
    display: none;
    text-align: center;
    padding: 50px 0 0 0;
    width: 960px;
    @media #{$mq_max_940} {
      width: 100%;
    }
  }
}

.no-histories {
  @media #{$mq_max_940} {
    padding: 30px 2% 0 2%;
  }
}

/* ==========================================================================
   Popup
   ========================================================================== */
.compare-table-wrapper {
  width:100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.compare-table {
  background: #fff;
  border: 1px solid #d6d6d6;
  border-collapse: collapse;
  margin: 0 auto;

  th {
    background: #d6d6d6;
    border: 1px solid #d6d6d6;
    font-size: 11px;
    font-weight: normal;
    padding: 8px;
  }

  td {
    border: 1px solid #d6d6d6;
    font-size: 11px;
    padding: 8px;
    text-align: center;

    sup {
      background: #EEFF11;
      color: #66AADD;
      display: inline-block;
      font-size: 11px;
      line-height: 1;
      padding: 2px 3px;
      vertical-align: top;
    }

    &.col-0 {
      text-align: left;
    }
  }

  th.col-6 {
    white-space: nowrap;
  }

  td {
    &.col-3, &.col-4, &.col-5, &.col-6, &.col-7, &.col-8, &.col-9, &.col-10, &.col-11 {
      padding: 0;
    }

    div {
      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        border-bottom: none;
      }
    }

    [class^="icon-"], [class*=" icon-"] {
      color: #828282;
    }

    a.remove-favorite span {
      color: #06d !important;
    }
  }
}

/* ==========================================================================
   Search result
   ========================================================================== */

#SearchWords {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  p {
    @media #{$mq_max_940} {
      width: 96%;
      padding: 1.2rem 2% 0 2%;
    }
  }
  span {
    font-size: 20px;
  }
  & + .force-margin-l {
    padding: 0 2%;
  }
}

.search-result {
  padding: 0 10px 35px 10px;

  p.title {
    font-size: 18px;
    padding: 10px 0 0 0 !important;
    margin: 0;
  }

  .url {
    padding: 4px 0 !important;
    margin: 0;
    overflow: hidden;
    width: 890px;
    @media #{$mq_max_940} {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      color: #006621;
    }
  }
}

.searchform {
  padding: 0 0 60px 0;
  @media #{$mq_max_940} {
    padding: 0 2%;
  }
}

.pagenation {
  text-align: center;
  padding: 15px 0 0 0;
  margin: 0 0 15px 0;
  @media #{$mq_max_940} {
    padding: 15px 0 ;
  }

  .page-numbers {
    background: #e1e1e1;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 5px 10px 5px 10px;
  }

  .current {
    color: #fff;
    background: #0066bb;
  }

  a {
    text-decoration: none;
  }
}

.pagenavi a {
  &:visited, &:hover, &:active {
    text-decoration: none;
  }
}

/* ==========================================================================
   Local navi
   ========================================================================== */

.local-navi {
  background: #efefef;
  padding: 25px 15px;

  h2 {
    font-size: 15px;
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 15px 0;

    li {
      border-bottom: 1px dashed #ccc;
      font-size: 12px;
      padding: 6px 10px;
    }
  }

  .form-group {
    border-top: 1px dashed #ccc;
    padding: 10px 0 0 0;

    dt {
      margin: 0;
      padding: 0 0 5px 0;
    }

    dd {
      margin: 0;
      padding: 0 0 20px 0;
    }

    label {
      cursor: pointer;
      font-size: 11px;
      display: block;

      input {
        vertical-align: middle;
      }
    }
  }
}

/* ==========================================================================
   Form
   ========================================================================== */

.form-group {
  input {
    &[type="text"], &[type="password"] {
      border: 1px solid #ddd;
      font-size: 14px;
      font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
      height: 29px;
      -webkit-appearance: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-indent: 8px;
      margin: 0;
      width: 100%;
    }
  }

  select {
    border: 1px solid #ddd;
    font-size: 14px;
    font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
    height: 28px;
    min-width: 100%;
  }

  textarea {
    border: 1px solid #ddd;
    font-size: 14px;
    font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
    height: 122px;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    width: 99.3333%;
  }
}

button.button {
  border: none;
  cursor: pointer;
  font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
  outline: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.button {
  background: #0066bb;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  padding: 12px 0;
  text-decoration: none;
  text-align: center;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
  margin: 0;
  @include mq('xsmall') {
    @include fontsize(11);
  }

  [class^="icon-"], [class*=" icon-"] {
    color: #fff;
    font-size: 16px;
    margin: 0 6px 0 0;
    vertical-align: middle;
  }

  &.small {
    [class^="icon-"], [class*=" icon-"] {
      font-size: 15px;
      margin: 0 6px 0 0;
      vertical-align: unset;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:visited, &:hover, &:active {
    color: #fff;
  }

  &.xsmall {
    font-size: 12px;
    padding: 4px 8px;
  }

  &.small {
    padding: 10px 15px;
  }

  &.yellow {
    background: #eeff11;
    color: #454545;

    [class^="icon-"], [class*=" icon-"] {
      color: #454545;
    }
  }

  &.white {
    color: #191919;
    background: #fff;
  }

  &.wide {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

.button-group {
  display: table;
  width: 720px;
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;

  li {
    display: table-cell;
  }

  .button {
    color: #191919;
    font-size: 15px;
    width: 320px;
    margin: 0 40px 0 0;
    padding: 16px 0;
  }
}

/* ==========================================================================
   Google Map
   ========================================================================== */

.gmap {
  width: 940px;
  height: 420px;
  margin: 0 auto 25px auto;
}

/* ==========================================================================
   YouTube
   ========================================================================== */

.youtube {
  display: flex;
  justify-content: center;
  margin: 0 auto 35px auto;
}

/* ==========================================================================
   MW WP Form
   ========================================================================== */

label {
  cursor: pointer;
}

.red {
  color: #FF1919;
}

.mw_wp_form {
  margin: 0 0 65px 0;

  dl {
    margin: 0 0 35px 0;
  }

  dt {
    padding: 10px 0 10px 0;
    position: absolute;
    width: 240px;
  }

  dd {
    padding: 10px 0 10px 260px;
  }

  p {
    text-align: center;
  }

  .error {
    background: #ffeded;
    border: 1px solid #b70000;
    display: block;
    padding: 3px 6px;
    position: relative;
    margin: 10px 0 0 0;

    &:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 10px;
      height: 0;
      width: 0;
      border: 6px solid transparent;
      border-bottom: 10px solid #b70000;
    }
  }

  input {
    &[type="submit"] {
      border: none;
      cursor: pointer;
      font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
      outline: none;
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
      transition: border .25s linear, color .25s linear, background-color .25s linear;
      background: #06b;
      color: #fff !important;
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.4;
      padding: 12px 40px;
      text-decoration: none;
      text-align: center;
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
      transition: border .25s linear, color .25s linear, background-color .25s linear;
      margin: 0;
    }

    &[name="confirm"] {
      margin-left: 20px;
    }
  }
}

.complete-message {
  padding: 200px 0;
  text-align: center;
}

/* ==========================================================================
   Product Inquiry Form
   ========================================================================== */

.inquiry-block {
  text-align: center;
}

ul.inquiry-poducts {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 630px;

  li {
    background: #fff;
    border: 1px solid #ccc;
    float: left;
    margin: 0 20px 20px 0;
    overflow: hidden;
    text-align: left;
    width: 190px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    img {
      background: #f1f1f1;
      margin: 0 10px 0 0;
    }
  }
}

/* ==========================================================================
   MRC Override
   ========================================================================== */
#wrapper {
  .container {
    #mrc_webform {
      .mrc_webform_row{
        .mrc_webform_a {
          input {
            width: auto !important;
            &[name="zipcode"] {
              width: 80px !important;
            }
            &[name="form2"] {
              width: 100% !important;
              box-sizing: border-box !important;
            }
          }
          textarea {
            width: 100% !important;
            box-sizing: border-box !important;
          }
        }
      }
    }
    #mrc_download {
      .mrc_webform_row {
        .mrc_webform_a {
          input {
            width: auto !important;
            &[name="zipcode"] {
              width: 80px !important;
            }
            &[name="form2"] {
              width: 100% !important;
              box-sizing: border-box !important;
            }
          }
          textarea {
            width: 100% !important;
            box-sizing: border-box !important;
          }
        }
      }
    }
  }
}