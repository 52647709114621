@charset "UTF-8";
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
  -webkit-text-size-adjust: none; }

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical; }

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */
.browserupgrade {
  margin: 0;
  background: #eed3d7;
  border-bottom: 1px solid #b94a48;
  color: #b94a48;
  font-size: 12px;
  padding: 10px 0;
  text-align: center; }

/* ==========================================================================
   Common
   ========================================================================== */
body {
  background: #eee;
  color: #454545;
  font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
  font-size: 13px;
  line-height: 1.8;
  margin: 0;
  padding: 0; }

#wrapper {
  width: 100%;
  padding: 20px 0 0 0; }
  @media (max-width: 940px) {
    #wrapper {
      padding-top: 58px; }
      #wrapper .container {
        width: 96%;
        margin: 0 2%; }
        #wrapper .container.white {
          width: 100%;
          margin: 0; } }

.container, .inner {
  width: 940px;
  margin: 0 auto; }

@media (max-width: 940px) {
  .container {
    width: 100%; }
  .inner {
    width: 100%; } }

.search-container.white {
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 25px;
  width: 940px; }
  @media (max-width: 940px) {
    .search-container.white {
      width: 100%;
      margin: 0 15px;
      border-radius: 0px; } }

.container.white {
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 25px;
  width: 890px;
  margin: 0 auto 30px auto; }
  @media (max-width: 940px) {
    .container.white {
      width: 100%;
      margin: 0 0 30px 0;
      padding: 0;
      border-radius: 0px; }
      .container.white p {
        padding: 1.2rem 2%; } }

.container.gray {
  background: #d6d6d6;
  padding: 25px 0 35px 0;
  width: 100%; }

a {
  color: #06b;
  text-decoration: none;
  -webkit-transition: all ease-in-out .5s;
  -moz-transition: all ease-in-out .5s;
  -o-transition: all ease-in-out .5s;
  transition: all ease-in-out .5s; }
  a:visited {
    color: #06b;
    text-decoration: none; }
  a:hover {
    color: #06b;
    text-decoration: underline; }
  a:active {
    color: #06b;
    text-decoration: none; }
  a img {
    border: none; }

.row {
  display: flex;
  align-items: center;
  border-collapse: collapse; }
  .row [class^="column-"], .row [class*=" column-"] {
    display: flex; }
  .row .column-2 {
    /*width: 50%;*/ }
  .row .column-3 {
    width: 290px; }
    @media (max-width: 940px) {
      .row .column-3 {
        width: 100%;
        margin: 0 2%; } }
  .row .column-4 {
    width: 214px; }

/* ==========================================================================
   Class
   ========================================================================== */
.tright {
  text-align: right; }

.tcenter {
  text-align: center; }

.end {
  padding-bottom: 45px; }

.force-margin-zero {
  margin-bottom: 0 !important; }

.force-margin-s {
  margin-bottom: 15px !important; }

.force-margin-m {
  margin-bottom: 25px !important; }

.force-margin-l {
  margin-bottom: 35px !important; }

.force-margin-xl {
  margin-bottom: 45px !important; }

.force-margin-xxl {
  margin-bottom: 55px !important; }

/* ==========================================================================
   Font
   ========================================================================== */
@font-face {
  font-family: 'shinkoh';
  src: url("../fonts/shinkoh.eot?gsqb96");
  src: url("../fonts/shinkoh.eot?gsqb96#iefix") format("embedded-opentype"), url("../fonts/shinkoh.ttf?gsqb96") format("truetype"), url("../fonts/shinkoh.woff?gsqb96") format("woff"), url("../fonts/shinkoh.svg?gsqb96#shinkoh") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shinkoh' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-cart:before {
  content: "\e93a"; }

.icon-download:before {
  content: "\e600"; }

.icon-inquiry:before {
  content: "\e601"; }

.icon-search:before {
  content: "\e602"; }

.icon-arrow-down2:before {
  content: "\e603"; }

.icon-home3:before {
  content: "\e902"; }

.icon-office:before {
  content: "\e903"; }

.icon-image:before {
  content: "\e90d"; }

.icon-images:before {
  content: "\e90e"; }

.icon-file-text:before {
  content: "\e922"; }

.icon-file-empty:before {
  content: "\e924"; }

.icon-files-empty:before {
  content: "\e925"; }

.icon-file-text2:before {
  content: "\e926"; }

.icon-file-picture:before {
  content: "\e927"; }

.icon-copy:before {
  content: "\e92c"; }

.icon-phone:before {
  content: "\e942"; }

.icon-envelop:before {
  content: "\e945"; }

.icon-user:before {
  content: "\e971"; }

.icon-user-tie:before {
  content: "\e976"; }

.icon-zoom-in:before {
  content: "\e987"; }

.icon-zoom-out:before {
  content: "\e988"; }

.icon-list:before {
  content: "\e9ba"; }

.icon-list2:before {
  content: "\e9bb"; }

.icon-tree:before {
  content: "\e9bc"; }

.icon-earth:before {
  content: "\e9ca"; }

.icon-star-empty:before {
  content: "\e9d7"; }

.icon-star-full:before {
  content: "\e9d9"; }

.icon-warning:before {
  content: "\ea07"; }

.icon-notification:before {
  content: "\ea08"; }

.icon-question:before {
  content: "\ea09"; }

.icon-info:before {
  content: "\ea0c"; }

.icon-cancel-circle:before {
  content: "\ea0d"; }

.icon-arrow-up:before {
  content: "\ea32"; }

.icon-arrow-right:before {
  content: "\ea34"; }

.icon-arrow-down:before {
  content: "\ea36"; }

.icon-arrow-left:before {
  content: "\ea38"; }

.icon-new-tab:before {
  content: "\ea7e"; }

.icon-file-pdf:before {
  content: "\eada"; }

.icon-file-word:before {
  content: "\eadc"; }

.icon-file-excel:before {
  content: "\eadd"; }

/*
 * Table styles
 */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0 2px;
  z-index: 10; }

table.dataTable thead th,
table.dataTable tfoot th {
  background: #d6d6d6;
  font-weight: normal; }

table.dataTable thead th,
table.dataTable thead td {
  background-color: #d6d6d6;
  color: #454545;
  padding: 10px 18px; }

table.dataTable thead th {
  background-color: #d6d6d6;
  padding: 15px 10px; }

table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none; }

table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111111; }

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting {
  cursor: pointer;
  *cursor: hand; }

table.dataTable thead .sorting {
  background-image: url("../images/sort_both.png");
  background-repeat: no-repeat;
  background-position: center right; }

table.dataTable thead .sorting_asc {
  background-image: url("../images/sort_asc.png");
  background-repeat: no-repeat;
  background-position: center right; }

table.dataTable thead .sorting_desc {
  background-image: url("../images/sort_desc.png");
  background-repeat: no-repeat;
  background-position: center right; }

table.dataTable thead .sorting_asc_disabled {
  background-image: url("../images/sort_asc_disabled.png");
  background-repeat: no-repeat;
  background-position: center right; }

table.dataTable thead .sorting_desc_disabled {
  background-image: url("../images/sort_desc_disabled.png");
  background-repeat: no-repeat;
  background-position: center right; }

/*
table.dataTable tbody tr {
  background-color: white;
}
*/
table.dataTable tbody tr.selected {
  background-color: #b0bed9; }

table.dataTable tbody th,
table.dataTable tbody td {
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6; }

table.dataTable th.center,
table.dataTable td.center,
table.dataTable td.dataTables_empty {
  text-align: center; }

table.dataTable th.right,
table.dataTable td.right {
  text-align: right; }

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
  border-top: 1px solid #dddddd; }

table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: none; }

table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd; }

table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #dddddd; }

table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
  border-top: none; }

table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9; }

table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
  background-color: #abb9d3; }

table.dataTable.hover tbody tr:hover,
table.dataTable.hover tbody tr.odd:hover,
table.dataTable.hover tbody tr.even:hover, table.dataTable.display tbody tr:hover,
table.dataTable.display tbody tr.odd:hover,
table.dataTable.display tbody tr.even:hover {
  background-color: whitesmoke; }

table.dataTable.hover tbody tr:hover.selected,
table.dataTable.hover tbody tr.odd:hover.selected,
table.dataTable.hover tbody tr.even:hover.selected, table.dataTable.display tbody tr:hover.selected,
table.dataTable.display tbody tr.odd:hover.selected,
table.dataTable.display tbody tr.even:hover.selected {
  background-color: #a9b7d1; }

table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3 {
  background-color: #f9f9f9; }

table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3 {
  background-color: #acbad4; }

table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #f1f1f1; }

table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #f3f3f3; }

table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: whitesmoke; }

table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #a6b3cd; }

table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #a7b5ce; }

table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #a9b6d0; }

table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #f9f9f9; }

table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #fbfbfb; }

table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #fdfdfd; }

table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #acbad4; }

table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #adbbd6; }

table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #afbdd8; }

table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.display tbody tr.odd:hover > .sorting_1,
table.dataTable.display tbody tr.even:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
  background-color: #eaeaea; }

table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.display tbody tr.odd:hover > .sorting_2,
table.dataTable.display tbody tr.even:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_2 {
  background-color: #ebebeb; }

table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.display tbody tr.odd:hover > .sorting_3,
table.dataTable.display tbody tr.even:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_3 {
  background-color: #eeeeee; }

table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.display tbody tr.odd:hover.selected > .sorting_1,
table.dataTable.display tbody tr.even:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_1 {
  background-color: #a1aec7; }

table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.display tbody tr.odd:hover.selected > .sorting_2,
table.dataTable.display tbody tr.even:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_2 {
  background-color: #a2afc8; }

table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.display tbody tr.odd:hover.selected > .sorting_3,
table.dataTable.display tbody tr.even:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_3 {
  background-color: #a4b2cb; }

table.dataTable.no-footer {
  /*  border-bottom: 1px solid #111111;*/ }

table.dataTable,
table.dataTable th,
table.dataTable td {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  /*  clear: both;*/
  *zoom: 1;
  zoom: 1; }

.dataTables_wrapper .dataTables_length {
  float: left; }

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right; }

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em; }

.dataTables_wrapper .dataTables_info {
  /*  clear: both;*/
  float: left;
  padding-top: 0.755em; }

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em; }

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background-color: #c7c9cc;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 10px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333333 !important;
  border: 1px solid transparent; }

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #fff !important;
  background-color: #222; }

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  /*
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
*/
  display: none; }

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  background-color: #222; }

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background-color: #222; }

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* IE10+ */
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* W3C */ }

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333333; }

.dataTables_wrapper .dataTables_scroll {
  clear: both; }

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch; }

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important; }

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #efefef; }

.dataTables_wrapper.no-footer div.dataTables_scrollHead {
  background: #d6d6d6; }

.dataTables_wrapper.no-footer div.dataTables_scrollHead .product-list-table {
  border: none !important; }

.dataTables_wrapper.no-footer div.dataTables_scrollHead table,
.dataTables_wrapper.no-footer div.dataTables_scrollBody table {
  border-bottom: none; }

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0; }

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em; } }

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em; } }

.dataTables_filter {
  padding: 0 0 10px 0; }

.dataTables_filter input {
  line-height: normal;
  zoom: normal;
  padding: 1px 0;
  margin: 0; }

/*!
*  - v1.2.0
* Homepage: http://bqworks.com/slider-pro/
* Author: bqworks
* Author URL: http://bqworks.com/
*/
/* Core
--------------------------------------------------*/
.slider-pro {
  position: relative;
  margin: 0 auto;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.sp-slides-container {
  position: relative; }

.sp-mask {
  position: relative;
  overflow: hidden; }

.sp-slides {
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000; }

.sp-slide {
  position: absolute; }

.sp-image-container {
  overflow: hidden; }

.sp-image {
  position: relative;
  display: block;
  border: none; }

.sp-no-js {
  overflow: hidden;
  max-width: 100%; }

/* Thumbnails
--------------------------------------------------*/
.sp-thumbnails-container {
  position: relative;
  overflow: hidden; }

.sp-top-thumbnails,
.sp-bottom-thumbnails {
  left: 0;
  margin: 0 auto; }

.sp-top-thumbnails {
  position: absolute;
  top: 0;
  margin-bottom: 4px; }

.sp-bottom-thumbnails {
  margin-top: 4px; }

.sp-left-thumbnails,
.sp-right-thumbnails {
  position: absolute;
  top: 0; }

.sp-right-thumbnails {
  right: 0;
  margin-left: 4px; }

.sp-left-thumbnails {
  left: 0;
  margin-right: 4px; }

.sp-thumbnails {
  position: relative; }

.sp-thumbnail {
  border: none; }

.sp-thumbnail-container {
  position: relative;
  display: block;
  overflow: hidden;
  float: left;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* Horizontal thumbnails
------------------------*/
.sp-top-thumbnails .sp-thumbnail-container,
.sp-bottom-thumbnails .sp-thumbnail-container {
  margin-left: 2px;
  margin-right: 2px; }

.sp-top-thumbnails .sp-thumbnail-container:first-child,
.sp-bottom-thumbnails .sp-thumbnail-container:first-child {
  margin-left: 0; }

.sp-top-thumbnails .sp-thumbnail-container:last-child,
.sp-bottom-thumbnails .sp-thumbnail-container:last-child {
  margin-right: 0; }

/* Vertical thumbnails 
----------------------*/
.sp-left-thumbnails .sp-thumbnail-container,
.sp-right-thumbnails .sp-thumbnail-container {
  margin-top: 2px;
  margin-bottom: 2px; }

.sp-left-thumbnails .sp-thumbnail-container:first-child,
.sp-right-thumbnails .sp-thumbnail-container:first-child {
  margin-top: 0; }

.sp-left-thumbnails .sp-thumbnail-container:last-child,
.sp-right-thumbnails .sp-thumbnail-container:last-child {
  margin-bottom: 0; }

/* Right thumbnails with pointer
--------------------------------*/
.sp-right-thumbnails.sp-has-pointer {
  margin-left: -13px; }

.sp-right-thumbnails.sp-has-pointer .sp-thumbnail {
  position: absolute;
  left: 18px;
  margin-left: 0 !important; }

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  content: '';
  position: absolute;
  height: 100%;
  border-left: 5px solid #F00;
  left: 0;
  top: 0;
  margin-left: 13px; }

.sp-right-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 50%;
  margin-top: -8px;
  border-right: 13px solid #F00;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent; }

/* Left thumbnails with pointer
-------------------------------*/
.sp-left-thumbnails.sp-has-pointer {
  margin-right: -13px; }

.sp-left-thumbnails.sp-has-pointer .sp-thumbnail {
  position: absolute;
  right: 18px; }

.sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  content: '';
  position: absolute;
  height: 100%;
  border-left: 5px solid #F00;
  right: 0;
  top: 0;
  margin-right: 13px; }

.sp-left-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  top: 50%;
  margin-top: -8px;
  border-left: 13px solid #F00;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent; }

/* Bottom thumbnails with pointer
---------------------------------*/
.sp-bottom-thumbnails.sp-has-pointer {
  margin-top: -13px; }

.sp-bottom-thumbnails.sp-has-pointer .sp-thumbnail {
  position: absolute;
  top: 18px;
  margin-top: 0 !important; }

.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  content: '';
  position: absolute;
  width: 100%;
  border-bottom: 5px solid #F00;
  top: 0;
  margin-top: 13px; }

.sp-bottom-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: 0;
  margin-left: -8px;
  border-bottom: 13px solid #F00;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; }

/* Top thumbnails with pointer
------------------------------*/
.sp-top-thumbnails.sp-has-pointer {
  margin-bottom: -13px; }

.sp-top-thumbnails.sp-has-pointer .sp-thumbnail {
  position: absolute;
  bottom: 18px; }

.sp-top-thumbnails.sp-has-pointer .sp-selected-thumbnail:before {
  content: '';
  position: absolute;
  width: 100%;
  border-bottom: 5px solid #F00;
  bottom: 0;
  margin-bottom: 13px; }

.sp-top-thumbnails.sp-has-pointer .sp-selected-thumbnail:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  bottom: 0;
  margin-left: -8px;
  border-top: 13px solid #F00;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; }

/* Layers
--------------------------------------------------*/
.sp-layer {
  position: absolute;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-backface-visibility: hidden; }

.sp-black {
  color: #FFF;
  background: black;
  background: rgba(0, 0, 0, 0.7); }

.sp-white {
  color: #000;
  background: white;
  background: rgba(255, 255, 255, 0.7); }

.sp-rounded {
  border-radius: 10px; }

.sp-padding {
  padding: 10px; }

/* Touch Swipe
--------------------------------------------------*/
.sp-grab {
  cursor: url(images/openhand.cur), move; }

.sp-grabbing {
  cursor: url(images/closedhand.cur), move; }

.sp-selectable {
  cursor: default; }

/* Caption
--------------------------------------------------*/
.sp-caption-container {
  text-align: center;
  margin-top: 10px; }

/* Full Screen
--------------------------------------------------*/
.sp-full-screen {
  margin: 0 !important;
  background-color: #000; }

.sp-full-screen-button {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.sp-full-screen-button:before {
  content: '\2195'; }

.sp-fade-full-screen {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }

.slider-pro:hover .sp-fade-full-screen {
  opacity: 1; }

/* Buttons
--------------------------------------------------*/
.sp-buttons {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 10px; }

.sp-button {
  width: 40px;
  height: 5px;
  background: #191919;
  border: none;
  margin: 4px;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer; }

.sp-selected-button {
  background-color: #66bbff; }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sp-button {
    width: 14px;
    height: 14px; } }

@media only screen and (min-width: 568px) and (max-width: 768px) {
  .sp-button {
    width: 16px;
    height: 16px; } }

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .sp-button {
    width: 18px;
    height: 18px; } }

/* Arrows
--------------------------------------------------*/
.sp-arrows {
  position: absolute; }

.sp-fade-arrows {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }

.sp-slides-container:hover .sp-fade-arrows {
  opacity: 1; }

.sp-horizontal .sp-arrows {
  width: 100%;
  left: 0;
  top: 50%;
  margin-top: -15px; }

.sp-vertical .sp-arrows {
  height: 100%;
  left: 50%;
  top: 0;
  margin-left: -10px; }

.sp-arrow {
  position: absolute;
  display: block;
  width: 20px;
  height: 30px;
  cursor: pointer; }

.sp-vertical .sp-arrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sp-horizontal .sp-previous-arrow {
  left: 20px; }

.sp-horizontal .sp-next-arrow {
  right: 20px; }

.sp-vertical .sp-previous-arrow {
  top: 20px; }

.sp-vertical .sp-next-arrow {
  bottom: 20px; }

.sp-previous-arrow:before,
.sp-previous-arrow:after,
.sp-next-arrow:before,
.sp-next-arrow:after {
  content: '';
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: #FFF; }

.sp-previous-arrow:before {
  left: 30%;
  top: 0;
  -webkit-transform: skew(145deg, 0deg);
  -ms-transform: skew(145deg, 0deg);
  transform: skew(145deg, 0deg); }

.sp-previous-arrow:after {
  left: 30%;
  top: 50%;
  -webkit-transform: skew(-145deg, 0deg);
  -ms-transform: skew(-145deg, 0deg);
  transform: skew(-145deg, 0deg); }

.sp-next-arrow:before {
  right: 30%;
  top: 0;
  -webkit-transform: skew(35deg, 0deg);
  -ms-transform: skew(35deg, 0deg);
  transform: skew(35deg, 0deg); }

.sp-next-arrow:after {
  right: 30%;
  top: 50%;
  -webkit-transform: skew(-35deg, 0deg);
  -ms-transform: skew(-35deg, 0deg);
  transform: skew(-35deg, 0deg); }

.ie8 .sp-arrow,
.ie7 .sp-arrow {
  width: 0;
  height: 0; }

.ie8 .sp-arrow:before,
.ie8 .sp-arrow:after,
.ie7 .sp-arrow:before,
.ie7 .sp-arrow:after {
  content: none; }

.ie8.sp-horizontal .sp-previous-arrow,
.ie7.sp-horizontal .sp-previous-arrow {
  border-right: 20px solid #FFF;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

.ie8.sp-horizontal .sp-next-arrow,
.ie7.sp-horizontal .sp-next-arrow {
  border-left: 20px solid #FFF;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

.ie8.sp-vertical .sp-previous-arrow,
.ie7.sp-vertical .sp-previous-arrow {
  border-bottom: 20px solid #FFF;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent; }

.ie8.sp-vertical .sp-next-arrow,
.ie7.sp-vertical .sp-next-arrow {
  border-top: 20px solid #FFF;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent; }

/* Thumbnail Arrows
--------------------------------------------------*/
.sp-thumbnail-arrows {
  position: absolute; }

.sp-fade-thumbnail-arrows {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }

.sp-thumbnails-container:hover .sp-fade-thumbnail-arrows {
  opacity: 1; }

.sp-top-thumbnails .sp-thumbnail-arrows,
.sp-bottom-thumbnails .sp-thumbnail-arrows {
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -12px; }

.sp-left-thumbnails .sp-thumbnail-arrows,
.sp-right-thumbnails .sp-thumbnail-arrows {
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -7px; }

.sp-thumbnail-arrow {
  position: absolute;
  display: block;
  width: 15px;
  height: 25px;
  cursor: pointer; }

.sp-left-thumbnails .sp-thumbnail-arrows .sp-thumbnail-arrow,
.sp-right-thumbnails .sp-thumbnail-arrows .sp-thumbnail-arrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sp-top-thumbnails .sp-previous-thumbnail-arrow,
.sp-bottom-thumbnails .sp-previous-thumbnail-arrow {
  left: 0px; }

.sp-top-thumbnails .sp-next-thumbnail-arrow,
.sp-bottom-thumbnails .sp-next-thumbnail-arrow {
  right: 0px; }

.sp-left-thumbnails .sp-previous-thumbnail-arrow,
.sp-right-thumbnails .sp-previous-thumbnail-arrow {
  top: 0px; }

.sp-left-thumbnails .sp-next-thumbnail-arrow,
.sp-right-thumbnails .sp-next-thumbnail-arrow {
  bottom: 0px; }

.sp-previous-thumbnail-arrow:before,
.sp-previous-thumbnail-arrow:after,
.sp-next-thumbnail-arrow:before,
.sp-next-thumbnail-arrow:after {
  content: '';
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: #FFF; }

.sp-previous-thumbnail-arrow:before {
  left: 30%;
  top: 0;
  -webkit-transform: skew(145deg, 0deg);
  -ms-transform: skew(145deg, 0deg);
  transform: skew(145deg, 0deg); }

.sp-previous-thumbnail-arrow:after {
  left: 30%;
  top: 50%;
  -webkit-transform: skew(-145deg, 0deg);
  -ms-transform: skew(-145deg, 0deg);
  transform: skew(-145deg, 0deg); }

.sp-next-thumbnail-arrow:before {
  right: 30%;
  top: 0;
  -webkit-transform: skew(35deg, 0deg);
  -ms-transform: skew(35deg, 0deg);
  transform: skew(35deg, 0deg); }

.sp-next-thumbnail-arrow:after {
  right: 30%;
  top: 50%;
  -webkit-transform: skew(-35deg, 0deg);
  -ms-transform: skew(-35deg, 0deg);
  transform: skew(-35deg, 0deg); }

.ie8 .sp-thumbnail-arrow,
.ie7 .sp-thumbnail-arrow {
  width: 0;
  height: 0; }

.ie8 .sp-thumbnail-arrow:before,
.ie8 .sp-thumbnail-arrow:after,
.ie7 .sp-thumbnail-arrow:before,
.ie7 .sp-thumbnail-arrow:after {
  content: none; }

.ie8 .sp-top-thumbnails .sp-previous-thumbnail-arrow,
.ie8 .sp-bottom-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-top-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-bottom-thumbnails .sp-previous-thumbnail-arrow {
  border-right: 12px solid #FFF;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent; }

.ie8 .sp-top-thumbnails .sp-next-thumbnail-arrow,
.ie8 .sp-bottom-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-top-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-bottom-thumbnails .sp-next-thumbnail-arrow {
  border-left: 12px solid #FFF;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent; }

.ie8 .sp-left-thumbnails .sp-previous-thumbnail-arrow,
.ie8 .sp-right-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-left-thumbnails .sp-previous-thumbnail-arrow,
.ie7 .sp-right-thumbnails .sp-previous-thumbnail-arrow {
  border-bottom: 12px solid #FFF;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent; }

.ie8 .sp-left-thumbnails .sp-next-thumbnail-arrow,
.ie8 .sp-right-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-left-thumbnails .sp-next-thumbnail-arrow,
.ie7 .sp-right-thumbnails .sp-next-thumbnail-arrow {
  border-top: 12px solid #FFF;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent; }

/* Video
--------------------------------------------------*/
a.sp-video {
  text-decoration: none; }

a.sp-video img {
  -webkit-backface-visibility: hidden;
  border: none; }

a.sp-video:after {
  content: '\25B6';
  position: absolute;
  width: 45px;
  padding-left: 5px;
  height: 50px;
  border: 2px solid #FFF;
  text-align: center;
  font-size: 30px;
  border-radius: 30px;
  top: 0;
  color: #FFF;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  margin: auto;
  line-height: 52px; }

/* CSS for preventing styling issues in WordPress
--------------------------------------------------*/
.slider-pro img.sp-image,
.slider-pro img.sp-thumbnail {
  max-width: none !important;
  max-height: none !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  -webkit-box-shadow: none !important;
  -mox-box-shadow: none !important;
  box-shadow: none !important;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none; }

.slider-pro a {
  position: static;
  transition: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important; }

.slider-pro iframe,
.slider-pro object,
.slider-pro video,
.slider-pro embed,
.slider-pro canvas {
  max-width: none;
  max-height: none; }

.slider-pro p.sp-layer {
  font-size: 14px;
  line-height: 1.4;
  margin: 0; }

.slider-pro h1.sp-layer {
  font-size: 32px;
  line-height: 1.4;
  margin: 0; }

.slider-pro h2.sp-layer {
  font-size: 24px;
  line-height: 1.4;
  margin: 0; }

.slider-pro h3.sp-layer {
  font-size: 19px;
  line-height: 1.4;
  margin: 0; }

.slider-pro h4.sp-layer {
  font-size: 16px;
  line-height: 1.4;
  margin: 0; }

.slider-pro h5.sp-layer {
  font-size: 13px;
  line-height: 1.4;
  margin: 0; }

.slider-pro h6.sp-layer {
  font-size: 11px;
  line-height: 1.4;
  margin: 0; }

.slider-pro img.sp-layer {
  border: none; }

/* ==========================================================================
   Header
   ========================================================================== */
.corporate-heder {
  width: 100%;
  background: #0081cc; }
  @media (max-width: 940px) {
    .corporate-heder {
      position: fixed;
      top: 0;
      z-index: 200; } }
  .corporate-heder .row {
    min-height: 58px;
    justify-content: space-between;
    align-items: center; }
  .corporate-heder .inner {
    font-size: 11px;
    margin: 0 auto;
    width: 940px; }
    @media (max-width: 940px) {
      .corporate-heder .inner {
        width: 100%;
        padding: 0; } }
    .corporate-heder .inner .column-2 {
      color: #fff;
      font-size: 14px;
      margin: 0; }
      .corporate-heder .inner .column-2 a {
        color: #fff;
        text-decoration: none; }
        .corporate-heder .inner .column-2 a .logo-text {
          display: inline-block;
          vertical-align: middle;
          vertical-align: -moz-middle-with-baseline;
          vertical-align: -webkit-baseline-middle;
          line-height: 1; }
          @media (max-width: 940px) {
            .corporate-heder .inner .column-2 a .logo-text {
              display: block;
              font-size: 11px;
              font-size: 0.6875rem; } }
          @media (max-width: 20em) {
            .corporate-heder .inner .column-2 a .logo-text {
              font-size: 10px;
              font-size: 0.625rem; } }
    .corporate-heder .inner .nichia-logo {
      width: 187px; }
      @media (max-width: 940px) {
        .corporate-heder .inner .nichia-logo {
          width: 124px; }
          .corporate-heder .inner .nichia-logo-wrapper {
            padding: 0 0 0 2%; } }
    .corporate-heder .inner .shinkoh-logo {
      width: 197px;
      margin-left: 5px; }
      @media (max-width: 940px) {
        .corporate-heder .inner .shinkoh-logo {
          width: 128px; }
          .corporate-heder .inner .shinkoh-logo-wrapper {
            padding: 0 2% 0 0; } }
      @media (max-width: 20em) {
        .corporate-heder .inner .shinkoh-logo {
          width: 98px; } }

.global-navi {
  background: #06b; }
  @media (max-width: 940px) {
    .global-navi {
      display: none; } }
  .global-navi ul li {
    display: table-cell;
    padding: 0;
    vertical-align: middle; }
    .global-navi ul li .icon-home3 {
      font-size: 17px;
      line-height: 1; }
    .global-navi ul li a {
      border-right: none;
      color: #fff;
      display: inline-block;
      font-size: 13px;
      padding: 12px 0;
      text-align: center;
      text-decoration: none; }
      .global-navi ul li a:hover {
        background: #004880; }
      .global-navi ul li a span {
        padding: 0 21px; }
      .global-navi ul li a .separator {
        border-right: 1px solid #88B8DF;
        padding: 0 20px 0 21px; }
    .global-navi ul li:last-child {
      position: relative;
      text-align: right;
      width: 150px; }
      .global-navi ul li:last-child .icon-search {
        cursor: pointer;
        position: absolute;
        right: 4px;
        top: 18px; }
    .global-navi ul li form input {
      width: 130px; }

.site-header .header-function {
  display: table-cell;
  text-align: right;
  width: 100%;
  vertical-align: top; }
  .site-header .header-function ul {
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0; }
    .site-header .header-function ul li {
      font-size: 11px;
      margin: 0 0 0 20px;
      display: inline; }
      .site-header .header-function ul li span {
        margin: 0 6px 0 0; }

.global-navi ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0; }

/* ==========================================================================
   SP Menu
   ========================================================================== */
@media (min-width: 941px) {
  .sp-menu {
    display: none; } }

@media (max-width: 940px) {
  .sp-menu ul {
    margin: 15px 0 0 10px;
    padding: 0;
    list-style: none;
    width: 38px;
    position: relative; }
    .sp-menu ul li {
      display: block;
      width: 80%;
      margin: auto;
      height: 3px;
      background: #fff;
      border-radius: 3px;
      position: absolute;
      left: 0;
      right: 0;
      top: calc((100% -3px)/2); }
      .sp-menu ul li:first-child {
        transform: translateY(-10px); }
      .sp-menu ul li:last-child {
        transform: translateY(10px); }
  .sp-menu.active ul li:first-child {
    transform: rotate(45deg); }
  .sp-menu.active ul li:nth-child(2) {
    opacity: 0; }
  .sp-menu.active ul li:last-child {
    transform: rotate(-45deg); } }

.sp-nav {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  right: -940px;
  margin-top: 58px;
  z-index: 200; }
  @media (min-width: 941px) {
    .sp-nav {
      display: none; } }
  .sp-nav-search {
    width: 96%;
    padding: 2%;
    border-bottom: 1px solid #ccc; }
    .sp-nav-search .internal-search {
      display: flex;
      justify-content: space-between;
      border: 1px solid #ccc;
      padding: 7px 3px; }
      .sp-nav-search .internal-search input {
        width: 100%;
        border: 0;
        outline: 0;
        font-size: 14px;
        font-size: 0.875rem; }
      .sp-nav-search .internal-search .icon-search {
        cursor: pointer;
        font-size: 24px;
        font-size: 1.5rem; }
  .sp-nav-list {
    list-style: none;
    padding: 0;
    margin: 0; }
    .sp-nav-list li {
      width: 100%;
      border-bottom: 1px solid #ccc; }
      .sp-nav-list li a {
        font-size: 16px;
        font-size: 1rem;
        display: block;
        padding: 1.2rem 2%;
        color: #222;
        position: relative; }
        .sp-nav-list li a:hover {
          text-decoration: none; }
        .sp-nav-list li a:after {
          font-family: 'shinkoh' !important;
          position: absolute;
          top: 25px;
          right: 2%;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          content: "\e603";
          transform: rotate(-90deg); }

/* ==========================================================================
   Footer
   ========================================================================== */
.site-footer {
  width: 100%;
  background: #929292 url(../images/bg-gray-dotted.png) left top repeat;
  margin: 0; }
  .site-footer .inner {
    padding: 10px 0; }
  .site-footer h2 {
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0;
    padding: 3px 0; }
  .site-footer .branch {
    width: 940px;
    justify-content: space-between; }
    @media (max-width: 940px) {
      .site-footer .branch {
        display: block;
        width: 100%; } }
    .site-footer .branch .column-3 {
      background: url(../images/bg-branch.png) left top no-repeat;
      font-size: 11px;
      font-style: normal;
      height: 120px;
      padding: 10px 0 0 0;
      width: 290px; }
      @media (max-width: 940px) {
        .site-footer .branch .column-3 {
          width: 96%;
          margin: 2% 2% 0 2%;
          padding: 0; } }
      .site-footer .branch .column-3 h3 {
        color: #fff;
        font-size: 15px;
        font-weight: normal;
        padding: 0 15px 0 0;
        text-align: center;
        width: 105px;
        margin-bottom: 0; }
      .site-footer .branch .column-3 address {
        font-style: normal;
        line-height: 1.2;
        width: 170px; }
        @media (max-width: 940px) {
          .site-footer .branch .column-3 address {
            padding: 1em 0 0 0; } }
        .site-footer .branch .column-3 address .address {
          display: block;
          padding: 0 0 15px 0;
          margin: 0; }
        .site-footer .branch .column-3 address .telephone {
          display: block;
          margin: 0; }
  .site-footer .copyright {
    background: #191919;
    color: #a6a6a6;
    padding: 10px 0;
    font-size: 12px;
    font-style: normal; }
    .site-footer .copyright .footer-navi {
      display: table-cell;
      list-style: none;
      padding: 0;
      margin: 0;
      width: 60%; }
      @media (max-width: 940px) {
        .site-footer .copyright .footer-navi {
          display: none; } }
      .site-footer .copyright .footer-navi li {
        display: inline;
        margin: 0 10px 0 0; }
        .site-footer .copyright .footer-navi li a {
          color: #a6a6a6;
          text-decoration: none; }
    .site-footer .copyright p {
      display: table-cell;
      font-size: 11px;
      text-align: right;
      width: 40%; }
      @media (max-width: 940px) {
        .site-footer .copyright p {
          width: 100%;
          text-align: center; } }

/* ==========================================================================
   To page top
   ========================================================================== */
.page-top {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 300; }

/* ==========================================================================
   Bread-crumb
   ========================================================================== */
.bread-crumb {
  font-size: 11px;
  margin: 0 0 15px 0; }
  @media (max-width: 940px) {
    .bread-crumb {
      display: none; } }

/* ==========================================================================
   Table
   ========================================================================== */
.yearly ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  .yearly ul li {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0; }

/* ==========================================================================
   Table
   ========================================================================== */
.normal-table {
  border-collapse: collapse; }
  .normal-table th {
    background: #BBDDFF;
    border: 3px solid #eee;
    font-weight: normal;
    padding: 12px;
    text-align: left; }
  .normal-table td {
    background: #fff;
    padding: 12px;
    border: 3px solid #eee; }

.product-list-table {
  border-collapse: collapse; }
  .product-list-table th {
    font-size: 11px;
    font-weight: normal; }
  .product-list-table .nowrap {
    white-space: nowrap; }
  .product-list-table td {
    font-size: 12px;
    padding: 8px;
    border-left: 1px solid #e1e1e1; }
    .product-list-table td:first-child {
      border-left: none; }
    .product-list-table td.column1, .product-list-table td.column8 {
      white-space: nowrap; }
    .product-list-table td.column4, .product-list-table td.column5, .product-list-table td.column6, .product-list-table td.column7, .product-list-table td.column8, .product-list-table td.column9, .product-list-table td.column10 {
      padding: 0; }
  .product-list-table tr:first-child td {
    border-top: none; }
  .product-list-table td span.fred {
    color: #f00;
    padding: 0 0 0 5px; }
  .product-list-table td a {
    text-decoration: none; }
  .product-list-table td div {
    border-bottom: 1px solid #e1e1e1; }
    .product-list-table td div:last-child {
      border-bottom: none; }
  .product-list-table td [class^="icon-"], .product-list-table td [class*=" icon-"] {
    font-size: 14px; }
  .product-list-table td.column3, .product-list-table td.column4, .product-list-table td.column5, .product-list-table td.column6, .product-list-table td.column7, .product-list-table td.column8, .product-list-table td.column9, .product-list-table td.column10 {
    text-align: center; }

.product-detail-link {
  display: block; }

.product-list-table td .new {
  background: #EEFF11;
  color: #66AADD;
  display: inline-block;
  font-size: 11px;
  line-height: 1;
  padding: 2px 3px;
  margin: 0 5px 0 0;
  vertical-align: top; }

.product-list-table td [class^="icon-"], .product-list-table td [class*=" icon-"] {
  color: #828282; }

.product-list-table td a.remove-favorite span {
  color: #06d !important; }

.product-list-table td .light-color {
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 450px;
  -moz-border-radius: 450px;
  border-radius: 450px;
  content: " ";
  display: inline-block;
  height: 8px;
  width: 8px; }
  .product-list-table td .light-color.blue {
    background: #006AB7; }
  .product-list-table td .light-color.green {
    background: #8DC556; }
  .product-list-table td .light-color.amber {
    background: #F6AD3B; }
  .product-list-table td .light-color.red {
    background: #E61B1B; }
  .product-list-table td .light-color.white {
    background: #ffffff; }
  .product-list-table td .light-color.warm-white {
    background: #FEF555; }
  .product-list-table td .light-color.bluish-green {
    background: #82CCD2; }
  .product-list-table td .light-color.uv {
    background: #924898; }
  .product-list-table td .light-color.candle-color {
    background: #FEE8A9; }
  .product-list-table td .light-color.yellow {
    background: #FDEF55; }
  .product-list-table td .light-color.royal-blue {
    background: #154AB7; }

.product-list-table td .product-list-table td a {
  text-decoration: none; }

.product-list-table.uv thead th {
  background: #d6d6d6;
  font-weight: normal;
  padding: 10px; }

.product-list-table.uv thead td {
  padding: 10px; }

.product-list-table.uv tbody th {
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6; }

.product-list-table.uv tbody td {
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6; }
  .product-list-table.uv tbody td img {
    text-align: center; }

.product-list-function {
  background: #BBDDFF;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
  display: block;
  position: relative;
  padding: 7px 7px 7px 173px;
  margin: 0;
  z-index: 100; }
  @media (max-width: 940px) {
    .product-list-function {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: none;
      padding: 0; } }
  .product-list-function .check-all {
    background: #6ad !important;
    -webkit-border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    border-top-left-radius: 10px;
    border: none;
    color: #fff !important;
    font-size: 13px !important;
    left: 0;
    padding: 12px 12px !important;
    position: absolute;
    top: 0;
    width: 140px; }
    @media (max-width: 940px) {
      .product-list-function .check-all {
        -webkit-border-top-left-radius: 0px;
        -moz-border-radius-topleft: 0px;
        border-top-left-radius: 0px;
        position: relative;
        width: 100%;
        padding: 12px 0 !important;
        order: 2; } }
    .product-list-function .check-all:hover {
      text-decoration: none; }
  .product-list-function .button.small {
    background: #0066BB;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: none;
    display: inline-block;
    color: #fff !important;
    font-size: 12px !important;
    padding: 6px 10px;
    margin: 0 0 0 0;
    box-shadow: #003366 0px 2px 0px 0px;
    -webkit-box-shadow: #003366 0px 2px 0px 0px;
    -moz-box-shadow: #003366 0px 2px 0px 0px; }
    .product-list-function .button.small:hover {
      text-decoration: none; }
  .product-list-function.up {
    -webkit-border-top-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-left-radius: 10px; }
    @media (max-width: 940px) {
      .product-list-function.up {
        flex-direction: column-reverse;
        -webkit-border-bottom-left-radius: 0px;
        -moz-border-radius-bottomleft: 0px;
        border-bottom-left-radius: 0px; } }
    .product-list-function.up .check-all {
      -webkit-border-top-left-radius: 0px;
      -moz-border-radius-topleft: 0px;
      border-top-left-radius: 0px;
      -webkit-border-bottom-left-radius: 10px;
      -moz-border-radius-bottomleft: 10px;
      border-bottom-left-radius: 10px; }
      @media (max-width: 940px) {
        .product-list-function.up .check-all {
          -webkit-border-bottom-left-radius: 0px;
          -moz-border-radius-bottomleft: 0px;
          border-bottom-left-radius: 0px; } }
  .product-list-function.down:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-top: 15px solid #6ad; }
  .product-list-function.up:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10px;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-bottom: 15px solid #6ad; }
  .product-list-function ul {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0; }
    @media (max-width: 940px) {
      .product-list-function ul {
        order: 1;
        width: 96%;
        padding: 0.5rem 2%; } }
    .product-list-function ul li {
      display: inline;
      padding: 0 10px 0 0; }
      .product-list-function ul li:last-child {
        text-align: right;
        float: right;
        margin: 2px; }
        @media (max-width: 940px) {
          .product-list-function ul li:last-child {
            padding: 0; } }
        .product-list-function ul li:last-child select {
          width: 60px;
          min-width: 60px; }
  .product-list-function form {
    display: none; }

.dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_paginate {
  display: none; }

/* ==========================================================================
   Favorite
   ========================================================================== */
.favorite-block {
  display: none;
  -moz-box-shadow: rgba(0, 0, 0, 0.25) 0 3px 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 3px 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 3px 10px;
  background: #d6d6d6;
  width: 704px;
  right: -800px;
  position: fixed;
  bottom: 0;
  z-index: 1000; }
  @media (max-width: 940px) {
    .favorite-block {
      width: 222px;
      right: -250px; } }
  .favorite-block .top {
    margin: 19px;
    min-height: 120px; }
    @media (max-width: 940px) {
      .favorite-block .top {
        max-height: 120px;
        overflow-x: auto; } }
  .favorite-block .bottom {
    background: #777;
    padding: 10px 0;
    width: 704px;
    display: flex;
    justify-content: center; }
    @media (max-width: 940px) {
      .favorite-block .bottom {
        width: 222px; } }
    .favorite-block .bottom .row {
      width: 604px;
      margin: 0 0 0 65px; }
      @media (max-width: 940px) {
        .favorite-block .bottom .row {
          width: 100%;
          margin: 0; } }
  .favorite-block:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-top: 15px solid #777; }
  .favorite-block .row {
    display: flex; }
    @media (max-width: 940px) {
      .favorite-block .row {
        flex-direction: column; } }
  .favorite-block .column-5 {
    width: 118px;
    padding: 0 19px 0 0; }
    @media (max-width: 940px) {
      .favorite-block .column-5 {
        padding: 0 0 19px 0; }
        .favorite-block .column-5:last-child {
          padding: 0; } }
    .favorite-block .column-5 a {
      background: #fff;
      border-bottom: 5px solid #454545;
      display: block;
      height: 106px;
      overflow: hidden;
      padding: 5px;
      position: relative;
      text-decoration: none;
      width: 108px; }
      .favorite-block .column-5 a img {
        background: #e1e1e1;
        display: inline-block;
        margin: 0 0 6px 0; }
      .favorite-block .column-5 a .icon-cancel-circle {
        background: #fff;
        -webkit-border-bottom-left-radius: 6px;
        -moz-border-radius-bottomleft: 6px;
        border-bottom-left-radius: 6px;
        color: #454545;
        right: 0px;
        padding: 4px;
        position: absolute;
        top: 0px; }
  .favorite-block .column-3 {
    margin: 0 10px 0 0; }
    .favorite-block .column-3:last-child {
      margin: 0; }
    @media (max-width: 940px) {
      .favorite-block .column-3 {
        margin: 0; } }
  .favorite-block .button {
    padding: 10px 20px; }
    @media (max-width: 940px) {
      .favorite-block .button {
        font-size: 12px;
        font-size: 0.75rem;
        padding: 10px 10px; } }
  .favorite-block .compare {
    width: 120px; }
    @media (max-width: 940px) {
      .favorite-block .compare {
        width: 186px;
        margin-bottom: 10px; }
        .favorite-block .compare a {
          width: 100%; } }
  .favorite-block .datasheet {
    width: 230px; }
    @media (max-width: 940px) {
      .favorite-block .datasheet {
        width: 186px;
        margin-bottom: 10px; }
        .favorite-block .datasheet a {
          width: 100%; } }
  .favorite-block .inquiry {
    width: 150px; }
    @media (max-width: 940px) {
      .favorite-block .inquiry {
        width: 186px; }
        .favorite-block .inquiry a {
          width: 100%; } }

.click-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0; }

.favorite-block .loading {
  display: none;
  padding: 50px 0 0 0;
  text-align: center;
  width: 666px; }
  @media (max-width: 940px) {
    .favorite-block .loading {
      width: 100%; } }

.no-favorite {
  padding: 50px 0 0 0;
  text-align: center;
  width: 666px; }
  @media (max-width: 940px) {
    .no-favorite {
      width: 100%; } }

/* ==========================================================================
   History
   ========================================================================== */
#histories .row {
  min-height: 132px; }
  @media (max-width: 940px) {
    #histories .row {
      width: 100%;
      overflow-y: auto;
      scroll-behavior: smooth; } }

#histories .column-7 {
  width: 118px;
  padding: 0 19px 0 0; }
  #histories .column-7 a {
    background: #fff;
    border-bottom: 5px solid #454545;
    display: block;
    height: 106px;
    overflow: hidden;
    padding: 5px;
    text-decoration: none;
    position: relative;
    width: 108px; }
    #histories .column-7 a img {
      background: #e1e1e1;
      display: inline-block;
      margin: 0 0 6px 0; }
    #histories .column-7 a .icon-cancel-circle {
      background: #fff;
      -webkit-border-bottom-left-radius: 6px;
      -moz-border-radius-bottomleft: 6px;
      border-bottom-left-radius: 6px;
      color: #454545;
      right: 0px;
      padding: 4px;
      position: absolute;
      top: 0px; }

#histories .loading {
  display: none;
  text-align: center;
  padding: 50px 0 0 0;
  width: 960px; }
  @media (max-width: 940px) {
    #histories .loading {
      width: 100%; } }

@media (max-width: 940px) {
  .no-histories {
    padding: 30px 2% 0 2%; } }

/* ==========================================================================
   Popup
   ========================================================================== */
.compare-table-wrapper {
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth; }

.compare-table {
  background: #fff;
  border: 1px solid #d6d6d6;
  border-collapse: collapse;
  margin: 0 auto; }
  .compare-table th {
    background: #d6d6d6;
    border: 1px solid #d6d6d6;
    font-size: 11px;
    font-weight: normal;
    padding: 8px; }
  .compare-table td {
    border: 1px solid #d6d6d6;
    font-size: 11px;
    padding: 8px;
    text-align: center; }
    .compare-table td sup {
      background: #EEFF11;
      color: #66AADD;
      display: inline-block;
      font-size: 11px;
      line-height: 1;
      padding: 2px 3px;
      vertical-align: top; }
    .compare-table td.col-0 {
      text-align: left; }
  .compare-table th.col-6 {
    white-space: nowrap; }
  .compare-table td.col-3, .compare-table td.col-4, .compare-table td.col-5, .compare-table td.col-6, .compare-table td.col-7, .compare-table td.col-8, .compare-table td.col-9, .compare-table td.col-10, .compare-table td.col-11 {
    padding: 0; }
  .compare-table td div {
    border-bottom: 1px solid #e1e1e1; }
    .compare-table td div:last-child {
      border-bottom: none; }
  .compare-table td [class^="icon-"], .compare-table td [class*=" icon-"] {
    color: #828282; }
  .compare-table td a.remove-favorite span {
    color: #06d !important; }

/* ==========================================================================
   Search result
   ========================================================================== */
#SearchWords {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px; }
  @media (max-width: 940px) {
    #SearchWords p {
      width: 96%;
      padding: 1.2rem 2% 0 2%; } }
  #SearchWords span {
    font-size: 20px; }
  #SearchWords + .force-margin-l {
    padding: 0 2%; }

.search-result {
  padding: 0 10px 35px 10px; }
  .search-result p.title {
    font-size: 18px;
    padding: 10px 0 0 0 !important;
    margin: 0; }
  .search-result .url {
    padding: 4px 0 !important;
    margin: 0;
    overflow: hidden;
    width: 890px; }
    @media (max-width: 940px) {
      .search-result .url {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; } }
    .search-result .url a {
      color: #006621; }

.searchform {
  padding: 0 0 60px 0; }
  @media (max-width: 940px) {
    .searchform {
      padding: 0 2%; } }

.pagenation {
  text-align: center;
  padding: 15px 0 0 0;
  margin: 0 0 15px 0; }
  @media (max-width: 940px) {
    .pagenation {
      padding: 15px 0; } }
  .pagenation .page-numbers {
    background: #e1e1e1;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 5px 10px 5px 10px; }
  .pagenation .current {
    color: #fff;
    background: #0066bb; }
  .pagenation a {
    text-decoration: none; }

.pagenavi a:visited, .pagenavi a:hover, .pagenavi a:active {
  text-decoration: none; }

/* ==========================================================================
   Local navi
   ========================================================================== */
.local-navi {
  background: #efefef;
  padding: 25px 15px; }
  .local-navi h2 {
    font-size: 15px;
    margin: 0;
    padding: 0; }
  .local-navi ul {
    list-style: none;
    margin: 0;
    padding: 0 0 15px 0; }
    .local-navi ul li {
      border-bottom: 1px dashed #ccc;
      font-size: 12px;
      padding: 6px 10px; }
  .local-navi .form-group {
    border-top: 1px dashed #ccc;
    padding: 10px 0 0 0; }
    .local-navi .form-group dt {
      margin: 0;
      padding: 0 0 5px 0; }
    .local-navi .form-group dd {
      margin: 0;
      padding: 0 0 20px 0; }
    .local-navi .form-group label {
      cursor: pointer;
      font-size: 11px;
      display: block; }
      .local-navi .form-group label input {
        vertical-align: middle; }

/* ==========================================================================
   Form
   ========================================================================== */
.form-group input[type="text"], .form-group input[type="password"] {
  border: 1px solid #ddd;
  font-size: 14px;
  font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
  height: 29px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-indent: 8px;
  margin: 0;
  width: 100%; }

.form-group select {
  border: 1px solid #ddd;
  font-size: 14px;
  font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
  height: 28px;
  min-width: 100%; }

.form-group textarea {
  border: 1px solid #ddd;
  font-size: 14px;
  font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
  height: 122px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  width: 99.3333%; }

button.button {
  border: none;
  cursor: pointer;
  font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
  outline: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear; }

.button {
  background: #0066bb;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  padding: 12px 0;
  text-decoration: none;
  text-align: center;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
  margin: 0; }
  @media (max-width: 20em) {
    .button {
      font-size: 11px;
      font-size: 0.6875rem; } }
  .button [class^="icon-"], .button [class*=" icon-"] {
    color: #fff;
    font-size: 16px;
    margin: 0 6px 0 0;
    vertical-align: middle; }
  .button.small [class^="icon-"], .button.small [class*=" icon-"] {
    font-size: 15px;
    margin: 0 6px 0 0;
    vertical-align: unset; }
  .button:hover {
    text-decoration: none; }
  .button:visited, .button:hover, .button:active {
    color: #fff; }
  .button.xsmall {
    font-size: 12px;
    padding: 4px 8px; }
  .button.small {
    padding: 10px 15px; }
  .button.yellow {
    background: #eeff11;
    color: #454545; }
    .button.yellow [class^="icon-"], .button.yellow [class*=" icon-"] {
      color: #454545; }
  .button.white {
    color: #191919;
    background: #fff; }
  .button.wide {
    width: 100%;
    padding-right: 0;
    padding-left: 0; }

.button-group {
  display: table;
  width: 720px;
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0; }
  .button-group li {
    display: table-cell; }
  .button-group .button {
    color: #191919;
    font-size: 15px;
    width: 320px;
    margin: 0 40px 0 0;
    padding: 16px 0; }

/* ==========================================================================
   Google Map
   ========================================================================== */
.gmap {
  width: 940px;
  height: 420px;
  margin: 0 auto 25px auto; }

/* ==========================================================================
   YouTube
   ========================================================================== */
.youtube {
  display: flex;
  justify-content: center;
  margin: 0 auto 35px auto; }

/* ==========================================================================
   MW WP Form
   ========================================================================== */
label {
  cursor: pointer; }

.red {
  color: #FF1919; }

.mw_wp_form {
  margin: 0 0 65px 0; }
  .mw_wp_form dl {
    margin: 0 0 35px 0; }
  .mw_wp_form dt {
    padding: 10px 0 10px 0;
    position: absolute;
    width: 240px; }
  .mw_wp_form dd {
    padding: 10px 0 10px 260px; }
  .mw_wp_form p {
    text-align: center; }
  .mw_wp_form .error {
    background: #ffeded;
    border: 1px solid #b70000;
    display: block;
    padding: 3px 6px;
    position: relative;
    margin: 10px 0 0 0; }
    .mw_wp_form .error:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 10px;
      height: 0;
      width: 0;
      border: 6px solid transparent;
      border-bottom: 10px solid #b70000; }
  .mw_wp_form input[type="submit"] {
    border: none;
    cursor: pointer;
    font-family: 'メイリオ',Meiryo,'verdana','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Osaka','ＭＳ Ｐゴシック','MS PGothic',Sans-Serif;
    outline: none;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    transition: border .25s linear, color .25s linear, background-color .25s linear;
    background: #06b;
    color: #fff !important;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    padding: 12px 40px;
    text-decoration: none;
    text-align: center;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    transition: border .25s linear, color .25s linear, background-color .25s linear;
    margin: 0; }
  .mw_wp_form input[name="confirm"] {
    margin-left: 20px; }

.complete-message {
  padding: 200px 0;
  text-align: center; }

/* ==========================================================================
   Product Inquiry Form
   ========================================================================== */
.inquiry-block {
  text-align: center; }

ul.inquiry-poducts {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 630px; }
  ul.inquiry-poducts li {
    background: #fff;
    border: 1px solid #ccc;
    float: left;
    margin: 0 20px 20px 0;
    overflow: hidden;
    text-align: left;
    width: 190px; }
    ul.inquiry-poducts li:nth-child(3n) {
      margin-right: 0; }
    ul.inquiry-poducts li img {
      background: #f1f1f1;
      margin: 0 10px 0 0; }

/* ==========================================================================
   MRC Override
   ========================================================================== */
#wrapper .container #mrc_webform .mrc_webform_row .mrc_webform_a input {
  width: auto !important; }
  #wrapper .container #mrc_webform .mrc_webform_row .mrc_webform_a input[name="zipcode"] {
    width: 80px !important; }
  #wrapper .container #mrc_webform .mrc_webform_row .mrc_webform_a input[name="form2"] {
    width: 100% !important;
    box-sizing: border-box !important; }

#wrapper .container #mrc_webform .mrc_webform_row .mrc_webform_a textarea {
  width: 100% !important;
  box-sizing: border-box !important; }

#wrapper .container #mrc_download .mrc_webform_row .mrc_webform_a input {
  width: auto !important; }
  #wrapper .container #mrc_download .mrc_webform_row .mrc_webform_a input[name="zipcode"] {
    width: 80px !important; }
  #wrapper .container #mrc_download .mrc_webform_row .mrc_webform_a input[name="form2"] {
    width: 100% !important;
    box-sizing: border-box !important; }

#wrapper .container #mrc_download .mrc_webform_row .mrc_webform_a textarea {
  width: 100% !important;
  box-sizing: border-box !important; }

/* ==========================================================================
   Main image
   ========================================================================== */
.main-image {
  background: url(../images/bg-repeat-dotted.png) left top repeat-x;
  width: 100%;
  padding: 0; }
  .main-image .sp-slide {
    z-index: 100;
    cursor: pointer; }
    @media (max-width: 940px) {
      .main-image .sp-slide {
        min-height: calc(100vw * (260 / 900)); } }
    .main-image .sp-slide a {
      display: block;
      width: 100%;
      height: auto; }
    .main-image .sp-slide img {
      width: 100%;
      height: auto; }

.category-image {
  width: 100%;
  padding: 0 0 20px 0; }
  .category-image .inner {
    position: relative; }
    .category-image .inner span {
      font-size: 20px;
      position: absolute;
      top: 60px;
      left: 30px;
      margin-top: -15px; }

.slide-summary {
  width: 940px;
  height: 260px; }
  @media (max-width: 940px) {
    .slide-summary {
      width: 100%; } }
  .slide-summary .sp-layer img {
    width: 100%; }

/* ==========================================================================
   News
   ========================================================================== */
#news h1 {
  position: relative;
  width: 100%; }
  @media (max-width: 940px) {
    #news h1 {
      margin: 2% 2% 0 2%; } }
  #news h1 .news-list {
    background: #ccc;
    font-size: 12px;
    display: block;
    padding: 3px 6px;
    position: absolute;
    top: 6px;
    right: 0; }

@media (max-width: 940px) {
  .news-content {
    padding: 1.2rem 2%; } }

.news-content dt {
  border-bottom: 1px solid #e1e1e1;
  padding: 10px 0 10px 15px;
  position: absolute; }
  @media (max-width: 940px) {
    .news-content dt {
      position: relative;
      padding: 10px 0 0 0;
      border-bottom: none; } }

.news-content dd {
  border-bottom: 1px solid #e1e1e1;
  padding: 10px 0 10px 90px; }
  @media (max-width: 940px) {
    .news-content dd {
      padding: 5px 0 5px 0px;
      margin-left: 0px; } }

/* ==========================================================================
   Top page
   ========================================================================== */
#products-search {
  padding: 0 0 15px 0;
  margin: 0 auto 30px auto; }
  @media (max-width: 940px) {
    #products-search {
      margin: 0 0 30px 0; }
      #products-search p.tcenter {
        padding: 0 2%; } }

.product-search {
  padding: 20px 35px; }
  @media (max-width: 940px) {
    .product-search {
      padding: 1.2rem 2%; } }
  .product-search dt {
    position: absolute;
    padding: 10px 0 5px 0; }
    @media (max-width: 940px) {
      .product-search dt {
        position: relative; } }
  .product-search dd {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0 5px 120px;
    margin: 0; }
    @media (max-width: 940px) {
      .product-search dd {
        padding-left: 0px; } }
    .product-search dd label {
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      margin: 0 10px 5px 0; }

.search-button {
  width: 256px; }

.button.flex {
  padding-left: 25px;
  padding-right: 25px; }

/* ==========================================================================
   Second
   ========================================================================== */
#search-result {
  margin: 0 auto 30px auto; }

.heading {
  margin: 0 auto 20px auto; }
  @media (max-width: 940px) {
    .heading {
      width: 96%;
      margin: 1.2rem 2%; } }
  .heading h1 {
    border-left: 7px solid #eeff11;
    font-size: 22px;
    font-weight: normal;
    line-height: 1.2;
    padding: 0 0 0 12px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative; }
    .heading h1 .cart-button, .heading h1 .favorite-button {
      position: absolute;
      display: inline-block;
      top: 0;
      right: 0;
      font-size: 14px;
      font-size: 0.875rem; }
      @media (max-width: 940px) {
        .heading h1 .cart-button .button, .heading h1 .favorite-button .button {
          font-size: 11px;
          font-size: 0.6875rem; } }
    .heading h1 .cart-button {
      right: 220px; }
      @media (max-width: 940px) {
        .heading h1 .cart-button {
          top: 66px;
          right: 0px; } }

.container .column-left .white, .container .column-right .white {
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 20px; }

.container .column-left {
  width: 182px; }
  @media (max-width: 940px) {
    .container .column-left {
      width: 100%; } }
  .container .column-left p {
    background: #fff;
    margin: 0; }
    .container .column-left p img {
      padding: 5px; }

.container .column-right {
  width: 720px;
  margin: 0 0 35px 0; }
  @media (max-width: 940px) {
    .container .column-right {
      width: 100%; } }
  .container .column-right table td .row {
    display: -webkit-flex;
    display: flex;
    width: 350px; }
  .container .column-right table td div {
    border-right: 3px solid #eee;
    -webkit-box-flex: 1;
    box-flex: 1;
    text-align: center; }
    .container .column-right table td div:last-child {
      border: none; }

.product-detail-container {
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 940px) {
    .product-detail-container {
      display: block; }
      .product-detail-container .column-left {
        text-align: center; } }

.compare-table td .light-color, .container .column-right table td .light-color {
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 450px;
  -moz-border-radius: 450px;
  border-radius: 450px;
  content: " ";
  display: inline-block;
  height: 8px;
  padding: 0;
  width: 8px; }

.compare-table td .light-color.blue, .container .column-right table td .light-color.blue {
  background: #006AB7; }

.compare-table td .light-color.green, .container .column-right table td .light-color.green {
  background: #8DC556; }

.compare-table td .light-color.amber, .container .column-right table td .light-color.amber {
  background: #F6AD3B; }

.compare-table td .light-color.red, .container .column-right table td .light-color.red {
  background: #E61B1B; }

.compare-table td .light-color.white, .container .column-right table td .light-color.white {
  background: #ffffff; }

.compare-table td .light-color.warm-white, .container .column-right table td .light-color.warm-white {
  background: #FEF555; }

.compare-table td .light-color.bluish-green, .container .column-right table td .light-color.bluish-green {
  background: #82CCD2; }

.compare-table td .light-color.uv, .container .column-right table td .light-color.uv {
  background: #924898; }

.compare-table td .light-color.candle-color, .container .column-right table td .light-color.candle-color {
  background: #FEE8A9; }

.compare-table td .light-color.yellow, .container .column-right table td .light-color.yellow {
  background: #FDEF55; }

.compare-table td .light-color.royal-blue, .container .column-right table td .light-color.royal-blue {
  background: #154AB7; }

.index-navi {
  width: 705px; }
  .index-navi .column-3 {
    border: 1px solid #ccc;
    display: inline-table;
    width: 208px;
    margin: 0 25px 25px 0; }
    .index-navi .column-3 a {
      display: block;
      padding: 9px; }
      .index-navi .column-3 a span {
        margin: 0 0 0 10px; }

.product-indexes {
  width: 705px; }
  .product-indexes .index {
    display: inline-table;
    width: 210px;
    margin: 0 25px 25px 0; }
    .product-indexes .index h3 {
      font-size: 15px;
      font-weight: normal;
      padding: 0 0 10px 0;
      margin: 0; }
    .product-indexes .index .row {
      width: 210px; }
    .product-indexes .index .left {
      display: table-cell;
      width: 70px;
      padding: 0 15px 0 0;
      vertical-align: top; }
    .product-indexes .index .right {
      display: table-cell;
      width: 115px;
      vertical-align: top; }
      .product-indexes .index .right p {
        font-size: 12px;
        padding: 0;
        margin: 0; }

/* ==========================================================================
   Product detail
   ========================================================================== */
.product-detail {
  margin: 0 0 35px 0; }
  .product-detail .column-2:first-child {
    width: 260px;
    padding: 0 40px 0 0; }
  .product-detail .column-2:last-child {
    width: 640px; }
  .product-detail .column-2 dt {
    position: absolute;
    margin: 10px 0; }
  .product-detail .column-2 dd {
    border-bottom: 1px dashed #ccc;
    padding: 10px 0 10px 130px;
    margin: 0; }

.down-load {
  background: #efefef;
  padding: 15px 20px;
  margin: 0 0 35px 0; }

.container h1 {
  position: relative; }

.heading h1 .new {
  background: #EEFF11;
  color: #66AADD;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 3px 5px;
  margin: 0 0 0 10px;
  vertical-align: top; }

.container h1 .favorit-button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0; }

.container h2 {
  font-size: 18px;
  font-weight: normal;
  position: relative; }

.inner h2 {
  font-size: 18px;
  font-weight: normal;
  position: relative; }
  @media (max-width: 940px) {
    .inner h2 {
      margin: 0 2%;
      width: 96%; } }

.container h2 .button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0; }

.container h3 {
  font-size: 16px;
  font-weight: normal;
  position: relative; }

.inner h3 {
  font-size: 16px;
  font-weight: normal;
  position: relative; }

.container p.tcenter img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 100%; }

.product-detail-table {
  border-collapse: collapse; }
  .product-detail-table th {
    background: #BBDDFF;
    border: 3px solid #eee;
    font-weight: normal;
    padding: 12px;
    text-align: left; }
  .product-detail-table td {
    background: #fff;
    border: 3px solid #eee; }
    .product-detail-table td table {
      border: none; }
      .product-detail-table td table td {
        border: none;
        border-right: 1px solid #ddd;
        text-align: center;
        padding: 0; }
        .product-detail-table td table td:last-child {
          border: none; }
      .product-detail-table td table[class^="series-col"] td, .product-detail-table td table[class*="series-col"] td {
        padding: 10px 0; }
      .product-detail-table td table.series-col1 td {
        width: 360px; }
      .product-detail-table td table.series-col2 td {
        width: 180px; }
      .product-detail-table td table.series-col3 td {
        width: 120px; }
      .product-detail-table td table.series-col4 td {
        width: 90px; }
      .product-detail-table td table.series-col5 td {
        width: 72px; }
      .product-detail-table td table.series-col6 td {
        width: 60px; }

/* ==========================================================================
   UVLED
   ========================================================================== */
.inner.uvled {
  margin: 0 auto 35px auto; }

/* ==========================================================================
   Swipeable
   ========================================================================== */
.dataTables_scroll {
  overflow-y: scroll; }
  @media (max-width: 940px) {
    .dataTables_scroll .dataTables_scrollHead {
      overflow: unset !important;
      min-width: 940px; }
    .dataTables_scroll .dataTables_scrollBody {
      overflow: unset !important;
      height: 160px !important;
      min-width: 940px; } }

@keyframes swipeable {
  0% {
    opacity: 0;
    left: 60%; }
  30% {
    opacity: 0.5; }
  100% {
    opacity: 1;
    left: 40%; } }

.swipeable {
  width: 100%;
  position: relative; }
  @media (max-width: 940px) {
    .swipeable::after {
      content: "";
      width: 40px;
      height: 40px;
      background-image: url(../images/swipeable.png);
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      animation: swipeable 1s ease-in 1s infinite alternate forwards running; }
    .swipeable.swiped::after {
      background-image: none; } }

@media (max-width: 940px) {
  .uvled .swipeable {
    overflow-y: scroll;
    height: 200px; }
    .uvled .swipeable .product-list-table {
      min-width: 940px; } }

/* ==========================================================================
   CMS settings
   ========================================================================== */
.ratio2x8 .column-2:first-child {
  width: 172px;
  padding-right: 30px; }

.ratio2x8 .column-2:last-child {
  width: 688px; }

.ratio3x7 .column-2:first-child {
  width: 258px;
  padding-right: 30px; }

.ratio3x7 .column-2:last-child {
  width: 602px; }

.ratio4x6 .column-2:first-child {
  width: 344px;
  padding-right: 30px; }

.ratio4x6 .column-2:last-child {
  width: 516px; }

.ratio5x5 .column-2:first-child {
  width: 430px;
  padding-right: 30px; }

.ratio5x5 .column-2:last-child {
  width: 430px; }

.ratio6x4 .column-2:first-child {
  width: 516px;
  padding-right: 30px; }

.ratio6x4 .column-2:last-child {
  width: 344px; }

.ratio7x3 .column-2:first-child {
  width: 602px;
  padding-right: 35px; }

.ratio7x3 .column-2:last-child {
  width: 258px; }

.ratio8x2 .column-2:first-child {
  width: 688px;
  padding-right: 35px; }

.ratio8x2 .column-2:last-child {
  width: 172px; }

.row[class*="ratio"] [class^="column-"], .row[class*="ratio"] [class*=" column-"] {
  display: block; }

@media (max-width: 940px) {
  .row[class*="ratio"] {
    display: block; }
    .row[class*="ratio"] .column-2 {
      width: 100%; }
      .row[class*="ratio"] .column-2:first-child {
        padding-right: 0; } }

[class^="ratio"] .column-2 img, [class*=" ratio"] .column-2 img {
  width: 100%; }

[class^="ratio"] .column-2 img.circle, [class*=" ratio"] .column-2 img.circle {
  -webkit-border-radius: 450px;
  -moz-border-radius: 450px;
  border-radius: 450px; }

table.ratio3x7 .col-0 {
  width: 30%; }

table.ratio3x7 .col-1 {
  width: 70%; }

table.ratio4x6 .col-0 {
  width: 40%; }

table.ratio4x6 .col-1 {
  width: 60%; }

table.ratio5x5 .col-0, table.ratio5x5 .col-1 {
  width: 50%; }

table.ratio6x4 .col-0 {
  width: 60%; }

table.ratio6x4 .col-1 {
  width: 40%; }

table.ratio7x3 .col-0 {
  width: 70%; }

table.ratio7x3 .col-1 {
  width: 30%; }
