@charset "utf-8";

/* ==========================================================================
   Header
   ========================================================================== */
.corporate-heder {
  width: 100%;
  background: #0081cc;
  @media #{$mq_max_940} {
    position: fixed;
    top: 0;
    z-index: 200;
  }
  .row {
    min-height: 58px;
    justify-content: space-between;
    align-items: center;
  }
  .inner {
    font-size: 11px;
    margin: 0 auto;
    width: 940px;
    @media #{$mq_max_940} {
      width: 100%;
      padding: 0;
    }

    .column-2 {
      color: #fff;
      font-size: 14px;
      margin: 0;

      a {
        color: #fff;
        text-decoration: none;

        .logo-text {
          display: inline-block;
          vertical-align: middle;
          vertical-align: -moz-middle-with-baseline;
          vertical-align: -webkit-baseline-middle;
          line-height: 1;
          @media #{$mq_max_940} {
            display: block;
            @include fontsize(11);
          }
          @include mq('xsmall') {
            @include fontsize(10);
          }
        }
      }
    }

    .nichia-logo {
      width: 187px;
      @media #{$mq_max_940} {
        width: 124px;
        &-wrapper {
          padding:0 0 0 2%;
        }
      }
    }

    .shinkoh-logo {
      width: 197px;
      margin-left: 5px;
      @media #{$mq_max_940} {
        width: 128px;
        &-wrapper {
          padding:0 2% 0 0 ;
        }
      }
      @include mq('xsmall') {
        width: 98px;
      }
    }
  }
}

.global-navi {
  background: #06b;
  @media #{$mq_max_940} {
    display: none;
  }
  ul li {
    display: table-cell;
    padding: 0;
    vertical-align: middle;

    .icon-home3 {
      font-size: 17px;
      line-height: 1;
    }

    a {
      border-right: none;
      color: #fff;
      display: inline-block;
      font-size: 13px;
      padding: 12px 0;
      text-align: center;
      text-decoration: none;

      &:hover {
        background: #004880;
      }

      span {
        padding: 0 21px;
      }

      .separator {
        border-right: 1px solid #88B8DF;
        padding: 0 20px 0 21px;
      }
    }

    &:last-child {
      position: relative;
      text-align: right;
      width: 150px;

      .icon-search {
        cursor: pointer;
        position: absolute;
        right: 4px;
        top: 18px;
      }
    }

    form input {
      width: 130px;
    }
  }
}

.site-header .header-function {
  display: table-cell;
  text-align: right;
  width: 100%;
  vertical-align: top;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0;

    li {
      font-size: 11px;
      margin: 0 0 0 20px;
      display: inline;

      span {
        margin: 0 6px 0 0;
      }
    }
  }
}

.global-navi ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* ==========================================================================
   SP Menu
   ========================================================================== */

@media #{$mq_min_941} {
  .sp-menu {
    display: none;
  }
}
@media #{$mq_max_940} {
  .sp-menu {
    ul {
      margin: 15px 0 0 10px;
      padding: 0;
      list-style: none;
      width: 38px;
      position: relative;
      li {
        display: block;
        width: 80%;
        margin: auto;
        height: 3px;
        background: #fff;
        border-radius: 3px;
        position: absolute;
        left: 0;
        right: 0;
        top: calc((100% -3px)/2);
        &:first-child {
          transform: translateY(-10px);
        }
        &:last-child {
          transform: translateY(10px);
        }
      }
    }
    &.active{
      ul {
        li {
          &:first-child {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

.sp-nav {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  right: -940px;
  margin-top: 58px;
  z-index: 200;
  @media #{$mq_min_941} {
    display: none;
  }
  &-search {
    width: 96%;
    padding: 2%;
    border-bottom: 1px solid #ccc;
    .internal-search {
      display: flex;
      justify-content: space-between;
      border: 1px solid #ccc;
      padding: 7px 3px;
      input {
        width: 100%;
        border: 0;
        outline: 0;
        @include fontsize(14);
      }
      .icon-search {
        cursor: pointer;
        @include fontsize(24);
      }
    }
  }
  &-list {
    list-style:none;
    padding: 0;
    margin: 0;
    li {
      width: 100%;
      border-bottom: 1px solid #ccc;
      a {
        @include fontsize(16);
        display: block;
        padding: 1.2rem 2%;
        color: #222;
        position: relative;
        &:hover {
          text-decoration: none;
        }
        &:after {
          font-family: 'shinkoh' !important;
          position: absolute;
          top: 25px;
          right: 2%;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          content: "\e603";
          transform: rotate(-90deg);
        }
      }
    }
  }
}