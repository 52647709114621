@charset "utf-8";

/* ==========================================================================
   Main image
   ========================================================================== */

.main-image {
  background: url(../images/bg-repeat-dotted.png) left top repeat-x;
  width: 100%;
  padding: 0;

  .sp-slide {
    z-index: 100;
    cursor: pointer;
    @media #{$mq_max_940} {
      min-height: calc(100vw * (260 / 900));
    }
    a {
      display: block;
      width: 100%;
      height: auto;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.category-image {
  width: 100%;
  padding: 0 0 20px 0;

  .inner {
    position: relative;

    span {
      font-size: 20px;
      position: absolute;
      top: 60px;
      left: 30px;
      margin-top: -15px;
    }
  }
}

.slide-summary {
  width: 940px;
  height: 260px;
  @media #{$mq_max_940} {
    width: 100%;
  }
  .sp-layer img {
    width: 100%;
  }
}

/* ==========================================================================
   News
   ========================================================================== */

#news h1 {
  position: relative;
  width: 100%;
  @media #{$mq_max_940} {
    margin: 2% 2% 0 2%;
  }

  .news-list {
    background: #ccc;
    font-size: 12px;
    display: block;
    padding: 3px 6px;
    position: absolute;
    top: 6px;
    right: 0;
  }
}

.news-content {
  @media #{$mq_max_940} {
    padding: 1.2rem 2%;
  }
  dt {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0 10px 15px;
    position: absolute;
    @media #{$mq_max_940} {
      position: relative;
      padding: 10px 0 0 0;
      border-bottom: none;
    }
  }

  dd {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0 10px 90px;
    @media #{$mq_max_940} {
      padding: 5px 0 5px 0px;
      margin-left: 0px;
    }
  }
}

/* ==========================================================================
   Top page
   ========================================================================== */

#products-search {
  padding: 0 0 15px 0;
  margin: 0 auto 30px auto;
  @media #{$mq_max_940} {
    margin: 0 0 30px 0;
    p.tcenter {
      padding:0 2%;
    }
  }
}

.product-search {
  padding: 20px 35px;
  @media #{$mq_max_940} {
    padding: 1.2rem 2%;
  }
  dt {
    position: absolute;
    padding: 10px 0 5px 0;
    @media #{$mq_max_940} {
      position: relative;
    }
  }

  dd {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 0 5px 120px;
    margin: 0;
    @media #{$mq_max_940} {
      padding-left: 0px;
    }

    label {
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      margin: 0 10px 5px 0;
    }
  }
}

.search-button {
  width: 256px;
}

.button.flex {
  padding-left: 25px;
  padding-right: 25px;
}